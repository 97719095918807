import { render, staticRenderFns } from "./BaseDropzone.vue?vue&type=template&id=2e228214&scoped=true"
import script from "./BaseDropzone.vue?vue&type=script&lang=js"
export * from "./BaseDropzone.vue?vue&type=script&lang=js"
import style0 from "../../node_modules/bulma/css/bulma.css?vue&type=style&index=0&id=2e228214&prod&lang=css&scoped=true&external"
import style1 from "./BaseDropzone.vue?vue&type=style&index=1&id=2e228214&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e228214",
  null
  
)

export default component.exports