// *************************************************
// Utility functions shared by components
// *************************************************

//import { _ } from "core-js";
import { titleize } from "../utils/utils.js";
import defaultImage from "../assets/image-not-available-130.png";

export function defaultIssueAssignee(route, role, familiarName) {
  // Issue default assignee rules
  // Hard-coding familiar names for now...
  if (route.includes("qpissue") && role.includes("QikPrep")) return "Regina";
  if (route.includes("qpissue") && !role.includes("QikPrep")) return "Arturo";
  if (route.includes("PurchasedItem") && role.includes("QikPrep"))
    return "Regina";
  if (route.includes("PurchasedItem") && !role.includes("QikPrep"))
    return "Arturo";
  return familiarName;
}

export function handleRouterPushError(error) {
  // Ignore the vuex err regarding  navigating to the page they are already on.
  if (
    error.name === "NavigationDuplicated" &&
    error.message.includes("Avoided redundant navigation to current location")
  ) {
    // Suppress this error
    console.log("Suppressed router push error: ", error.message);
    return;
  } else if (
    error.message.includes("Navigation aborted from") &&
    error.message.includes("via a navigation guard")
  ) {
    // Suppress error
    console.log("Suppressed router push error: ", error.message);
    return;
  } else {
    // Unknown error
    console.error("Routing error occurred: ", error);
  }
}

export function setSubNavText(funcSetText, funcSetAccent, mainNavText) {
  // Set subnav title and color
  if (mainNavText === "Issue") {
    // Issue: Red
    funcSetText("Issue");
    funcSetAccent("255, 0, 0");
    document.title = "g3tools - Issues";
  } else if (mainNavText === "Online Arbitrage") {
    // OAR: Green
    funcSetText("OAR - Online Arbitrage");
    funcSetAccent("0, 153, 51");
    document.title = "g3tools - Online Arbitrage";
  } else if (mainNavText === "Product") {
    // Product: Purple
    funcSetText("Product");
    funcSetAccent("127, 44, 209");
    document.title = "g3tools - Product";
  } else if (mainNavText === "Amazon") {
    // Amazon: Orange
    funcSetText("Amazon");
    funcSetAccent("197, 137, 48");
    document.title = "g3tools - Amazon";
  } else if (mainNavText === "Purchasing") {
    // Purchasing: Blue
    funcSetText("Purchasing");
    funcSetAccent("7,144,212");
    document.title = "g3tools - Purchasing";
  } else if (mainNavText === "Inventory") {
    // Inventory: Violet
    funcSetText("Inventory");
    funcSetAccent("209, 44, 193");
    document.title = "g3tools - Inventory";
  }
}
// *********************************************
//          Standard Item Methods
// *********************************************
export function handleBeforeDestroy(thisObj, document) {
  document.removeEventListener("focusin", thisObj.focusChanged);
}

// Created life cycle hook for component
export async function handleCreated(thisObj) {
  // Initialize and sync current item with items[] object from list
  await handleLoadFormFromItem(thisObj);
  thisObj.form.clientId = thisObj.$route.query.callingClientId;
  thisObj.form.tenantId = thisObj.$route.query.callingTenantId;
  thisObj.form.id = thisObj.$route.params.id;
  thisObj.mode = thisObj.form.id === -1 ? "new" : "update";
  if (thisObj.$v) {
    thisObj.$v.$reset();
    thisObj.childDirty = false;
  }
  if (thisObj.mode !== "new") {
    // Fetch record from db
    await thisObj.$store.dispatch(`${thisObj.namespace}/fetchItem`, {
      id: thisObj.form.id,
      tenantId: thisObj.form.tenantId,
      clientId: thisObj.form.clientId,
    });
    await handleLoadFormFromItem(thisObj);
    if (thisObj.$v) {
      thisObj.$v.$reset();
      thisObj.childDirty = false;
    }
  } else {
    // New item
    await thisObj.$store.dispatch(
      `${thisObj.namespace}/createNewItem`,
      thisObj.currentItem
    );
  }
  // Load currentItem into form object
  await handleLoadForm(thisObj);
  // Set client id if default (0) and calling client id is not default
  if (
    (!thisObj.form.clientId || thisObj.form.clientId === "0") &&
    thisObj.$route.query.callingClientId &&
    thisObj.$route.query.callingClientId !== "0"
  ) {
    // Set client id and client name from caller
    thisObj.form.clientId = thisObj.$route.query.callingClientId;
    thisObj.form.clientName = thisObj.$route.query.callingClientName;
    thisObj.$store.commit(`${thisObj.namespace}/CURRENT_ITEM_FIELD_SET`, {
      key: "clientId",
      value: thisObj.$route.query.callingClientId,
    });
    thisObj.$store.commit(`${thisObj.namespace}/CURRENT_ITEM_FIELD_SET`, {
      key: "clientName",
      value: thisObj.$route.query.callingClientName,
    });
  }
  // Reload copy
  thisObj.$store.commit(
    `${thisObj.namespace}/CURRENT_ITEM_COPY_SET`,
    thisObj.currentItem
  );
}

export function handleItemImage(thisObj) {
  if (thisObj.currentItem?.imageUrl || thisObj.form.imageUrl) {
    thisObj.isDefaultImage = false;
    if (thisObj.currentItem?.imageUrl) {
      return thisObj.currentItem?.imageUrl?.replace("_SL40", "_SL130");
    } else {
      return thisObj.form.imageUrl?.replace("_SL40", "_SL130");
    }
  } else {
    thisObj.isDefaultImage = true;
    return defaultImage;
  }
}

export async function handleLoadForm(thisObj) {
  // Load form data from currentItem
  if (!thisObj) return;
  for (let key in thisObj?.form) {
    if (thisObj?.currentItem && key in thisObj.currentItem) {
      const currData = thisObj.currentItem[key];
      thisObj.form[key] = currData;
    }
  }
}

export async function handleLoadFormFromItem(thisObj) {
  // Load form data from item, if passed in as prop
  if (!thisObj) return;
  const useItem = thisObj.item || thisObj.currentItem || null;
  if (!useItem) return;
  for (let key in thisObj?.form) {
    if (useItem) {
      const currData = useItem[key];
      thisObj.form[key] = currData;
    }
  }
}

export function handleSyncOptionName({
  thisObj,
  value,
  valueField,
  textField,
  optionsField,
  descriptionField = "",
}) {
  // Find filter object matching field
  const filter = thisObj.$store.getters[`${thisObj.namespace}/filters`]?.find(
    (filter) => filter.idField === valueField
  );
  const selectedOption = filter[optionsField]?.find(
    (option) => option[valueField] === value
  );
  if (selectedOption) {
    thisObj.form[textField] = selectedOption[textField];
    if (descriptionField) {
      thisObj.form[descriptionField] = selectedOption[descriptionField];
    }
  }
}

export function handleValidateState(thisObj, data) {
  const field = thisObj.$v.form[data];
  if (!field) return null;
  if (field.$error) {
    return false;
  }
  return null;
}

// Changes store values (component to store data binding)
export function handleUpdateLocalValue(data, thisObj) {
  if (!data.field) return;
  let { field, value } = data;
  // Nullify dates
  if (field.toLowerCase().includes("date") && value === "") {
    value = null;
  }
  // Update store
  thisObj.$store.dispatch(`${thisObj.namespace}/currentItemFieldSet`, {
    key: field,
    value: value,
  });
  // Update local form object
  if (thisObj.form[field] !== undefined) {
    thisObj.form[field] = value;
  }
  // Update validate object
  if (thisObj.$v.form[field]) {
    thisObj.$v.form[field].$touch();
  }
}

// *********************************************
//          Item validate/close/cancel Methods
// *********************************************
export async function handleFormCancel(thisObj, route = true) {
  try {
    if (thisObj.$v.form.$anyDirty) {
      // Dirty: Confirm before close
      thisObj.confirmingChanges = true;
      const reply = await handleConfirmChanges(
        thisObj,
        "Do you want to save changes?"
      );
      thisObj.confirmingChanges = false;
      if (reply === null) {
        // Cancel cancel and go back
        return null;
      } else if (reply) {
        // Yes, save before leaving
        await thisObj.formSave(true);
      } else {
        // No. Don't save before cancel; just route
        // Restore currentItem
        thisObj.$store.commit(
          `${thisObj.namespace}/CURRENT_ITEM_RESTORE_FROM_COPY`,
          thisObj.currentItem.id
        );
        if (thisObj.namespace === "Issue2") {
          await Promise.all([
            // Remove attachments added
            thisObj.$store.dispatch(`${thisObj.namespace}/cleanupAttachments`),
            // Delete unused control number
            deleteUnusedControlNumber(thisObj),
          ]);
        }
        thisObj.$v.$reset();
        if (route) {
          await popRoute(thisObj, false);
        } else return true;
      }
    } else {
      // Form is clean; just route
      await deleteUnusedControlNumber(thisObj);
      if (route) {
        await popRoute(thisObj, false);
      } else return true;
    }
  } catch (err) {
    console.error("Error in formCancel: ", err);
    throw err;
  }
}

// Typically used for ok/cancel when user should go back in the route history
export async function popRoute(thisObj, refreshList = false) {
  const lastRoute = thisObj.$store.getters["Session/lastRoute"];
  if (lastRoute) {
    thisObj.$store.commit("Session/POP_ROUTE_HISTORY");
    // Set flag so we don't push a popped route (see popRoute())
    thisObj.$store.commit("Session/SET_NAVIGATION_BACK_FLAG", true);
    lastRoute.params.reload = refreshList;
    lastRoute.params.fromRecId = thisObj.form.id;
    await thisObj.$router.push({
      ...lastRoute,
    });
  } else {
    thisObj.$router.push({ path: "/" }); // Go home
  }
}

export async function deleteUnusedControlNumber(thisObj) {
  if (thisObj.mode === "update") return;
  const payload = {
    clientId: thisObj.form.clientId,
    tenantId: thisObj.form.tenantId,
    controlNumber: thisObj.form.controlNumber,
  };
  await thisObj.$store.dispatch(
    `${thisObj.namespace}/cleanupControlNumber`,
    payload
  );
}

export async function handleFormSave(thisObj, route = true) {
  try {
    if (thisObj.confirmingChanges) return;
    thisObj.$v.$touch();
    if (thisObj.$v.form.$anyError) {
      handleShowAlert(thisObj);
      return false;
    }
    // Save item
    await handleSave(thisObj);
    if (route) {
      thisObj.$v.$reset();
      // Route back
      await popRoute(thisObj, true);
    } else return true;
  } catch (err) {
    console.error("Error in handleFormSave: ", err);
    throw err;
  }
}

export function handleShowAlert(thisObj) {
  try {
    const msg = "Please fix errors before saving";
    thisObj.$store.dispatch(
      "Notification/alertErrorAdd",
      { message: msg, show: 7 },
      { root: true }
    );
    return false;
  } catch (err) {
    console.error("Error in handleShowAlert: ", err);
    throw err;
  }
}

// Save item
export async function handleSave(thisObj) {
  if (thisObj.mode === "new") {
    // Create item
    if (
      !(await thisObj.$store.dispatch(
        `${thisObj.namespace}/itemCreate`,
        thisObj.form
      ))
    ) {
      return false;
    }
  } else {
    // Save existing item
    if (
      !(await thisObj.$store.dispatch(
        `${thisObj.namespace}/itemSave`,
        thisObj.form
      ))
    ) {
      return false;
    }
  }
  // Reset validations
  thisObj.$v.$reset();
  return true;
}

// Confirm changes
export async function handleConfirmChanges(thisObj, message) {
  // Confirm cancel
  const confirmOptions = {
    title: "Please Confirm",
    okTitle: "Yes",
    okVariant: "primary",
    cancelTitle: "No",
    cancelVariant: "light",
    titleClass: "pl-2",
    footerClass: "p-2",
    bodyClass: "p-2 pl-4 pr-3 text-pre-line",
    hideHeaderClose: false,
    centered: true,
    html: true,
  };
  try {
    const reply = await thisObj.$bvModal.msgBoxConfirm(message, confirmOptions);
    // Cancel the cancel
    if (reply === null) return null;
    return reply ? true : false;
  } catch (error) {
    // Return null to safely handle modal destruction
    return null;
  }
}

// Confirm changes
export async function handleConfirmChanges2({
  thisObj,
  message,
  okVariant = "primary",
  cancelVariant = "light",
  size = "",
}) {
  // Confirm cancel
  const confirmOptions = {
    title: "Please Confirm",
    okVariant: okVariant,
    cancelVariant: cancelVariant,
    okTitle: "Yes",
    cancelTitle: "No",
    footerClass: "p-2",
    titleClass: "pl-2",
    bodyClass: "p-2 pl-4 pr-3 text-pre-line",
    hideHeaderClose: false,
    size,
    centered: true,
    html: true,
  };
  const reply = await thisObj?.$bvModal.msgBoxConfirm(message, confirmOptions);
  // Cancel the cancel
  if (reply === null) return null;
  else if (reply) {
    // Yes, save before leaving
    return true;
  } else {
    // No. Don't save before cancel
    return false;
  }
}

// Confirm changes
export async function handleFixErrorsMsg(thisObj, message) {
  const confirmOptions = {
    title: "A Problem Occurred",
    okOnly: "true",
    okVariant: "danger",
    footerClass: "p-2",
    titleClass: "pl-2",
    bodyClass: "p-2 pl-4 pr-3 text-pre-line",
    hideHeaderClose: false,
    centered: true,
  };
  await thisObj.$bvModal.msgBoxOk(message, confirmOptions);
}

export function formatRawHtml(obj) {
  let txtDisplay = "";
  const keyStyle = `style="font-weight: 600; color:#000;"`; // Style for bold keys
  //const parsedObj = JSON.parse(obj);
  for (const key of Object.keys(obj)) {
    const properKey = titleize(key); // Capitalizes the key properly
    let value = obj[key]?.toString(); // Convert value to string
    txtDisplay += `<span ${keyStyle}>${properKey}</span>: ${value}<br>`;
  }
  return txtDisplay;
}

export function formatCrudAction(action) {
  if (action.toLowerCase() === "update") return "action-update";
  else if (action.toLowerCase() === "delete") return "action-delete";
  return "action-insert";
}

export function formatCurrency(amt, decimalDigits) {
  const fmtr = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimalDigits !== undefined ? decimalDigits : 2,
    maximumFractionDigits: decimalDigits !== undefined ? decimalDigits : 2,
  });
  return fmtr.format(amt);
}

export function formatPercent(value) {
  if (value === undefined) return;
  const useValue = value.toString().replace("%", "");
  if (value === "0%") return value;
  if (value === "0") return "0%";
  const pctValue = (useValue * 100).toFixed(0);
  return pctValue ? `${pctValue}%` : "0%";
}

export const postMsgBoxOk = async (
  thisObj,
  title,
  message,
  okVariant = "primary"
) => {
  thisObj.$bvModal
    .msgBoxOk(message, {
      title: title,
      okVariant,
      centered: true,
      titleClass: "pl-2",
      bodyClass: "p-2 pl-4 pr-3 text-pre-line",
    })
    .catch(() => {});
  return;
};

// **************************************************************************
//          Show/Hide modal detail
// **************************************************************************
// export const handleModalShowItemDetail = async (props) => {
//   const { item, thisObj, modalId, verifyClient = false } = props;
//   // Ensure tenant has selected a client
//   if (
//     (item === -1 || item.id === -1) &&
//     verifyClient &&
//     thisObj.user?.tenantSelectedClient?.clientId === "0"
//   ) {
//     // Tenant user must select client
//     thisObj.clientSelectMsgBox("Create Item Detail");
//     return;
//   }
//   // Hide spinner while loading modal
//   thisObj.useShowLoadingSpinner = false;

//   await thisObj.$bvModal.show(modalId);

//   setTimeout(() => {
//     thisObj.useShowLoadingSpinner = true;
//   }, 1000);
// };

export const handleModalOnOutsideClick = async (
  event,
  thisObj,
  modalWrapperRef,
  modalRef
) => {
  if (!thisObj) {
    return;
  }
  if (!thisObj.$refs) {
    return;
  }
  // Get b-modal wrapper where ref name is <namespace>ItemModalRef. Need this to get the dialog bounds
  const modalWrapperInstance = thisObj.$refs[`${modalWrapperRef}`];
  // Get b-modal child (component being displayed)
  const modalChildInstance = thisObj.$refs[`${modalRef}`];
  const modalDialogElement = modalWrapperInstance?.$refs.dialog;
  if (modalDialogElement && modalDialogElement.getBoundingClientRect) {
    const rect = modalDialogElement.getBoundingClientRect();
    if (
      event.clientX < rect.left ||
      event.clientX > rect.right ||
      event.clientY < rect.top ||
      event.clientY > rect.bottom
    ) {
      if (typeof modalChildInstance.formCancel === "function") {
        modalChildInstance.formCancel();
      } else {
        console.error(
          "formCancel method is not found on modal/detail component"
        );
      }
    } else {
      //          console.log("Click is inside the modal, do nothing");
    }
  } else {
    //console.error("Unable to get the modal element's bounding rectangle");
  }
};

export const handleCopyClicked = (thisObj, field) => {
  if (thisObj.form[field]) {
    navigator.clipboard
      .writeText(thisObj.form[field])
      .then(() => {
        // Show check icon
        thisObj[`showCopyCheck${field}`] = true;
        setTimeout(() => {
          thisObj[`showCopyCheck${field}`] = false;
        }, 1000);
        setTimeout(() => {}, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
        alert(`Unable to copy ${field}`);
      });
  }
};

// *************************************************
// Breadcrumb and routing

export const handleNavClick = (thisObj, name) => {
  // Recursive function to search for a route by name
  const findRouteByName = (routes, name) => {
    for (const route of routes) {
      // If the route name matches, return the route
      if (route.name === name) {
        return route;
      }
      // If the route has children, search them as well
      if (route.children) {
        const childRoute = findRouteByName(route.children, name);
        if (childRoute) {
          return childRoute;
        }
      }
    }
    return null;
  };
  // Find the route by name
  const route = findRouteByName(thisObj.$router.options.routes, name);
  // If the route exists and has a breadcrumb in meta, use it
  if (route && route.meta && route.meta.breadcrumb) {
    const breadcrumbData = { name, ...route.meta.breadcrumb };
    thisObj.$store.commit("Session/RESET_BREADCRUMB", [breadcrumbData]);
    if (thisObj.$route.name !== name) {
      thisObj.$router.push({ name }).catch((err) => {
        handleRouterPushError(err);
      });
    }
  } else {
    // Default breadcrumb if no meta found
    thisObj.$store.commit("Session/RESET_BREADCRUMB", [
      { text: "Unknown Route", name: "NotFound", title: "" },
    ]);
    thisObj.$router.push({ name: "NotFound" });
  }
};




