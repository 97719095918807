import { getPastDateInLocal } from "../../utils/date-utils.js";
import config from "../../../config.js";

export function getFields(scope) {
  const fields = [
    {
      key: "index",
      sortable: false,
      label: "",
      class: "col-right",
    },
    {
      key: "selected",
      sortable: false,
      class: "col-center col-40",
    },
    // key: "image" to create show image
    {
      key: "image",
      label: "Image",
      sortable: false,
      sortBy: "asin",
      class: "col-action col-60",
    },
    {
      // key: "action" to create link to detail item
      key: "productId",
      label: "Asin",
      sortable: true,
      sortBy: "asin",
      class: "col-action",
    },
    {
      key: "id",
      label: "Id",
      sortable: true,
      class: "col-70 col-center",
    },
    {
      key: "hasActiveIssue",
      label: "Issue",
      class: "col-center",
      sortable: true,
    },
    {
      key: "clientName",
      label: "Client",
      sortable: true,
    },
    {
      key: "productDescription",
      label: "Product Title",
      sortable: true,
      class: "",
      formatter: (value, key, item) => {
        return item.productDescription?.length > 100
          ? `${item.productDescription.substring(0, 100)}...`
          : item.productDescription;
      },
      style: {
        // width: "calc(100% - 10px)",
        maxWidth: "200px",
        width: "100%",
      },
    },
    {
      key: "supplier",
      label: "Supplier",
      sortable: true,
      tooltip: "Product supplier (store)",
    },
    {
      key: "source",
      label: "Source",
      sortable: true,
      tooltip: "Source of lead",
    },
    // {
    //   // key: "amazonLink" to create link to Amazon item
    //   key: "amazonLink",
    //   label: "Asin",
    //   sortable: true,
    //   showLink: false,
    //   sortBy: "asin",
    //   class: "col-action",
    // },
    // {
    //   key: "clientLobName",
    //   label: "Client LOB",
    //   sortable: true,
    //   tooltip: "Client line of business",
    // },
    // {
    //   key: "clientFacilityName",
    //   label: "Client Facility",
    //   sortable: true,
    //   tooltip: "Client facility that sourced item",
    // },
    // {
    //   key: "processorFacilityName",
    //   label: "Processor Facility",
    //   sortable: true,
    //   tooltip: "Process facility where item will be processed",
    // },
    {
      key: "purchasedQty",
      label: "Purch",
      sortable: true,
      class: ["col-center"],
      showTotal: true,
      tooltip: "Total quantity purchased",
    },
    {
      key: "pastReceivedQty",
      label: "Rcvd",
      sortable: true,
      class: ["col-center"],
      showTotal: true,
      tooltip: "Total quantity received",
    },
    {
      key: "issueQtySum",
      label: "Issue",
      sortable: true,
      class: ["col-center"],
      showTotal: true,
      tooltip: "Total items with issues",
    },
    {
      key: "issueQtyReceivable",
      label: "Receivable",
      sortable: true,
      class: ["col-center"],
      showTotal: true,
      tooltip: "Issue quantity with Action 'Receive Item'",
    },
    {
      key: "pendingQty",
      label: "Pending",
      sortable: true,
      class: ["col-center col-bold"],
      showTotal: true,
      tooltip: "Total quantity pending (includes Receivable)",
    },
    {
      key: "status",
      label: "Status",
      sortable: true,
      sortBy: "status",
      tooltip: "Status of Purchased Item",
    },
    {
      key: "pctComplete",
      label: "%Compl",
      sortable: true,
      //          class: ["col-100", "col-center"],
      class: ["col-center"],
      formatter: (value, key, item) => {
        return `${(item.pctComplete * 100).toFixed(0)}%`;
      },
    },
    {
      key: "purchasedOn",
      label: "Purch Dt",
      sortable: true,
      formatter: (value, key, item) => {
        return (
          item.purchasedOn && new Date(item.purchasedOn).toLocaleDateString()
        );
      },
    },
    {
      key: "orderNumber",
      label: "Order Number",
      sortable: true,
      sortBy: "orderNumber",
    },
    {
      key: "ts",
      label: "Modified",
      class: ["col-center"],
      sortable: true,
      formatter: (value, key, item) => {
        return item.ts && new Date(item.ts).toLocaleDateString();
      },
    },
  ];
  // Remove clientName if client scope
  if (scope === "client") {
    return fields.filter((field) => field.key !== "clientName");
  }
  return fields;
}

// Type: Select means a drop down list
// Type: Action means action buttons, like status.
export function getFilters(scope) {
  const filters = [
    {
      type: "select",
      field: "supplier",
      label: "Supplier",
      options: [], // Must be an array of strings
      itemOptions: [],
      includeInTable: true,
      value: null,
      operator: "=",
      multiSelect: false,
      allowBulkChange: false,
      fetchOptions: true,
    },
    {
      type: "select",
      field: "issueCount",
      label: "",
      options: [], // Must be an array of strings
      itemOptions: [],
      includeInTable: false,
      value: null,
      operator: "=",
      multiSelect: false,
      allowBulkChange: false,
      fetchOptions: false,
    },
    {
      type: "select",
      field: "origin",
      label: "Origin",
      idField: "originId",
      nameField: "originName",
      displayField: "originName",
      includeInTable: true,
      options: [],
      itemOptions: [],
      value: null,
      operator: "=",
      multiSelect: false,
      allowBulkChange: true,
      fetchOptions: true,
    },
    {
      type: "select",
      field: "source",
      label: "Source",
      options: [], // Must be an array of strings
      itemOptions: [],
      includeInTable: true,
      value: null,
      operator: "=",
      multiSelect: false,
      allowBulkChange: false,
      fetchOptions: true,
    },
    {
      type: "select",
      field: "clientName",
      label: "Client Name",
      idField: "clientId",
      nameField: "clientName",
      displayField: "clientName",
      includeInTable: true,
      options: [],
      itemOptions: [],
      defaultOperator: "=",
      defaultValue: "",
      value: null,
      operator: "=",
      multiSelect: false,
      allowBulkChange: true,
      fetchOptions: true,
    },
    {
      type: "select",
      field: "clientLobName",
      label: "Client LOB",
      idField: "clientLobId",
      nameField: "clientLobName",
      displayField: "clientLobName",
      includeInTable: false,
      options: [],
      itemOptions: [],
      value: null,
      operator: "=",
      multiSelect: false,
      allowBulkChange: true,
      fetchOptions: true,
    },
    {
      type: "select",
      field: "clientFacilityName",
      label: "Client Facility",
      idField: "clientFacilityId",
      nameField: "clientFacilityName",
      displayField: "clientFacilityName",
      includeInTable: false,
      options: [],
      itemOptions: [],
      defaultOperator: "=",
      defaultValue: "",
      value: null,
      operator: "=",
      multiSelect: false,
      allowBulkChange: true,
      fetchOptions: true,
    },
    {
      type: "select",
      field: "processorLobName",
      label: "Processor LOB",
      idField: "processorLobId",
      nameField: "processorLobName",
      displayField: "processorLobName",
      includeInTable: false,
      options: [],
      itemOptions: [],
      value: null,
      operator: "=",
      multiSelect: false,
      allowBulkChange: true,
      fetchOptions: true,
    },
    {
      type: "select",
      field: "processorFacilityName",
      label: "Processor Facility",
      idField: "processorFacilityId",
      nameField: "processorFacilityName",
      displayField: "processorFacilityName",
      includeInTable: false,
      options: [],
      itemOptions: [],
      defaultOperator: "=",
      defaultValue: "",
      value: null,
      operator: "=",
      multiSelect: false,
      allowBulkChange: true,
      fetchOptions: true,
    },
    {
      type: "action",
      field: "status",
      label: "Status",
      idField: "status",
      nameField: "status",
      displayField: "status",
      value: null,
      defaultValue: "",
      defaultOperator: "=",
      operator: "=",
      includeInTable: true,
      options: [
        { status: "Pending" },
        { status: "Complete" },
        { status: "All" },
      ],
      itemOptions: [],
      allowBulkChange: false,
      fetchOptions: false,
    },
    {
      type: "action",
      field: "deleted",
      label: "Deleted",
      idField: "deleted",
      nameField: "deleted",
      displayField: "deleted",
      value: null,
      defaultValue: "false",
      defaultOperator: "=",
      operator: "=",
      includeInTable: false,
      options: [],
      itemOptions: [],
      allowBulkChange: false,
      fetchOptions: false,
    },
    {
      // Used for custom where clause > XXd
      type: "select",
      field: "purchasedOn",
      label: "Purchased On",
      includeInTable: false,
      defaultValue: "",
      operator: "<=",
      fetchOptions: false,
    },
  ];
  // Remove client filter if client
  if (scope === "client") {
    return filters.filter((filter) => filter.field !== "clientName");
  }
  return filters;
}

export const dynamicFilters = (state, context) => {
  // Default condition: Receive pending
  // Status filter
  context.commit("FILTER_INCLUDE_IN_TABLE_VALUE_SET", {
    key: "status",
    value: false,
  });
  context.commit("FILTER_DEFAULT_VALUE_SET", {
    key: "status",
    value: "Pending",
  });
  // PurchasedOn filter
  context.commit("FILTER_DEFAULT_VALUE_SET", {
    key: "purchasedOn",
    value: "",
  });
  // issueCount filter
  context.commit("FILTER_DEFAULT_VALUE_SET", {
    key: "issueCount",
    value: null,
  });
  context.commit("FILTER_DEFAULT_VALUE_SET", {
    key: "deleted",
    value: "false",
  });
  context.commit("LIST_SET", { key: "sortBy", value: "ts" });
  context.commit("LIST_SET", { key: "sortOrder", value: "DESC" });
  // Set filters based on route name
  switch (state?.list?.routeName) {
    case "PurchasedReceiveXXd":
      // Receive > 21d
      context.commit("FILTER_INCLUDE_IN_TABLE_VALUE_SET", {
        key: "status",
        value: true,
      });
      context.commit("FILTER_DEFAULT_VALUE_SET", {
        key: "purchasedOn",
        value: getPastDateInLocal(config.pendingDays),
      });
      context.commit("FILTER_VALUE_SET", {
        key: "purchasedOn",
        value: getPastDateInLocal(config.pendingDays),
      });
      context.commit("FILTER_OPERATOR_SET", {
        key: "purchasedOn",
        value: "<",
      });
      context.commit("FILTER_DEFAULT_VALUE_SET", {
        key: "issueCount",
        value: 0,
      });
      context.commit("FILTER_VALUE_SET", {
        key: "issueCount",
        value: 0,
      });
      context.commit("LIST_SET", { key: "sortBy", value: "purchasedOn" });
      context.commit("LIST_SET", { key: "sortOrder", value: "ASC" });
      break;
    case "PurchasedReceiveAll":
      // Receive all
      context.commit("FILTER_INCLUDE_IN_TABLE_VALUE_SET", {
        key: "status",
        value: true,
      });
      context.commit("FILTER_VALUE_SET", {
        key: "status",
        value: "",
      });
      context.commit("FILTER_DEFAULT_VALUE_SET", {
        key: "status",
        value: "All",
      });
      break;
    case "PurchasedReceiveDeleted":
      // Deleted issues
      context.commit("FILTER_DEFAULT_VALUE_SET", {
        key: "status",
        value: null,
      });
      context.commit("FILTER_DEFAULT_VALUE_SET", {
        key: "deleted",
        value: true,
      });
      context.commit("FILTER_INCLUDE_IN_TABLE_VALUE_SET", {
        key: "deleted",
        value: false,
      });
      break;
  }
};

// Fields for File upload into PurchasedItem
export const uploadFieldsMap = [
  {
    name: "productDescription",
    label: "Product description",
    mappedLabel: null,
    isHovered: false,
    isRequired: true,
    pctDataFilled: 0,
  },
  {
    name: "orderNumber",
    label: "Order number",
    mappedLabel: null,
    isHovered: false,
    pctDataFilled: 0,
  },
  {
    name: "productId",
    label: "Product id (Asin)",
    mappedLabel: null,
    isHovered: false,
    isRequired: true,
    pctDataFilled: 0,
  },
  {
    name: "sku",
    label: "SKU",
    mappedLabel: null,
    isHovered: false,
    pctDataFilled: 0,
  },
  {
    name: "upc",
    label: "UPC",
    mappedLabel: null,
    isHovered: false,
    pctDataFilled: 0,
  },
  {
    name: "source",
    label: "Source",
    mappedLabel: null,
    isHovered: false,
    pctDataFilled: 0,
  },
  {
    name: "supplier",
    label: "Supplier",
    mappedLabel: null,
    isHovered: false,
    pctDataFilled: 0,
  },
  {
    name: "trackingNumber",
    label: "Tracking number",
    mappedLabel: null,
    isHovered: false,
    pctDataFilled: 0,
  },
  {
    name: "purchasedQty",
    label: "Purchased quantity",
    mappedLabel: null,
    isHovered: false,
    isRequired: true,
    pctDataFilled: 0,
  },
  {
    name: "purchasedOn",
    label: "Purchased on",
    mappedLabel: null,
    isHovered: false,
    isRequired: true,
    pctDataFilled: 0,
  },
  {
    name: "canceledQty",
    label: "Canceled quantity",
    mappedLabel: null,
    isHovered: false,
    pctDataFilled: 0,
  },
  {
    name: "replacementQty",
    label: "Replacement quantity",
    mappedLabel: null,
    isHovered: false,
    pctDataFilled: 0,
  },
  {
    name: "extraQty",
    label: "Extra quantity",
    mappedLabel: null,
    isHovered: false,
    pctDataFilled: 0,
  },
  {
    name: "clientNotes",
    label: "Client notes",
    mappedLabel: null,
    isHovered: false,
    pctDataFilled: 0,
  },
  {
    name: "itemNotes",
    label: "Item notes",
    mappedLabel: null,
    isHovered: false,
    pctDataFilled: 0,
  },
  {
    name: "expiry",
    label: "Expiry",
    mappedLabel: null,
    isHovered: false,
    pctDataFilled: 0,
  },
  {
    name: "cost",
    label: "Cost",
    mappedLabel: null,
    isHovered: false,
    pctDataFilled: 0,
  },
  {
    name: "msrp",
    label: "MSRP",
    mappedLabel: null,
    isHovered: false,
    pctDataFilled: 0,
  },
];

export const sorteduploadFieldsMap = () => {
  uploadFieldsMap.sort((a, b) => a.label.localeCompare(b.label));
  return uploadFieldsMap;
};

export const defaultPurchasedItem = (user) => {
  const record = {
    id: -1,
    tenantId: user.tenantSelectedClient?.tenantId
      ? user.tenantSelectedClient.tenantId
      : user.tenantId,
    clientId: user.tenantSelectedClient?.clientId
      ? user.tenantSelectedClient?.clientId
      : user.clientId,
    processorId: user.processorId,
    processorFacilityId: user.processorFacilityId,
    clientFacilityId: user.clientFacilityId,
    processorLobId: user.processorLobId,
    clientLobId: user.clientLobId,
    createdBy: user.userId,
    lastUpdatedBy: user.userId,
  };
  return record;
};