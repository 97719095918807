const config = {
  app: {
    version: "2.1.4",
    allowNegativeTotalReceived:
      process.env.VUE_APP_ALLOW_NEGATIVE_TOTAL_RECEIVED === "true",
    allowProcessorIssueActionChange: false,
  },
  SCOPES: {
    TENANT: "tenant",
    CLIENT: "client",
  },
  maxTablePages: 7,
  autoListRefreshIntervalMin: 5,
  fetchDetailItemFromDb: true,
  mws: {
    throttleWaitTimeInSeconds: 120,
    batchPauseSeconds: 1.5,
  },
  testMode: false,
  fileUploads: {
    MAX_SIZE: 20000000,
    FILE_TYPES: ["image/jpeg", "image/png", "image/gif", "video/mp4"],
    MAX_ROWS: 5000,
  },
  pendingDays: 14,
};

export default config;
