<template>
  <!-- Issues List -->
  <div class="issues-list-container">
    <div class="issues-list-header"  :class="{ 'issues-list-header-active': activeIssueQty > 0 && !disabled}"
>
        <span class="font-weight-bold font-md">Issues</span>
        <div class="row-style">
          <span class="mr-1" >Issue qty: </span>
          <span class="font-weight-bold">{{ totalIssueQty || 0 }}</span>
          <span class="ml-2 font-italic" >({{ activeIssueQty || 0 }} active)</span>
        </div>
        <div class="row-style ">
          <div class="mr-2" >Count: </div>
          <div class="font-weight-bold">{{ issueCount || 0 }}</div>
        </div>
    </div>
    <div class="issues-scrollable-list">
      <div>
        <p v-if="issueCount===0" class="no-issues mx-auto">No linked issues</p>
      </div>
      <!-- Issues List -->
      <div v-for="(item) in localIssues" :key="item.id">
        <!-- Each item -->
        <div class="item-container" :class="{'item-disabled': disabled }" @click="onItemClick(item)" @mouseover="hoveredItem = item.id" @mouseleave="hoveredItem = null">
          <div class="row-style">
            <!-- Left (qty) column -->
            <div class="col-1 d-flex justify-content-left">
              <!-- Issue qty -->
              <div class="qty-container">
                <div class="label-font">Qty</div>
                <div class="field">{{ item.issueQty }}</div>
              </div>
            </div>
            <!-- Right main column -->
            <div class="col-2">
              <b-row class="row-style">
                <!-- Reason/type -->
                <div class="field-container">
                  <div class="label-right-align">Reason:</div>
                  <span class="fixed-field-left" >{{ item.issueType.typeName }}</span>
                </div>
                <!-- Status -->
                <div class="field-container">
                  <span class="label-right-align">Status:</span>
                  <span class="fixed-field-right">{{ item.issueStatus.statusName }}</span>
                </div>
                <div v-bind:class="{'issue-flag-active': !item.issueStatus.closedOption, 'issue-flag-inactive': item.issueStatus.closedOption}">
                  <i v-b-tooltip.hover :title="item.isActive ? 'Active issue' : 'Closed issue'" class="fas fa-flag"></i>
                </div>
                <!-- Id -->
                <!-- <span class="label-font ml-auto">#{{ item?.id }}</span> -->
                <!-- Control number -->
                <span class="label-font text-dark font-weight-bold ml-auto">{{ item?.controlNumber }}</span>
              </b-row>
              <b-row class="row-style">
                <!-- Action -->
                <div class="field-container">
                  <span class="label-right-align">Action:</span>
                  <span class="fixed-field-left" >{{ item.issueAction?.actionName }}</span>
                </div>
                <!-- Assignee -->
                <div class="d-flex justify-content-between align-items-center">
                  <div class="field-container">
                    <span class="label-right-align">To:</span>
                    <span class="field" style="width: 90px">{{ item.authUser.familiarName }}</span>
                  </div>
                </div>
                <!-- Right side: Ellipsis dropdown for extra actions (unlink, delete) -->
                <div v-if="hoveredItem === item.id">
                  <b-dropdown
                    id="action-dropdown"
                    variant="link"
                    class="more-action-button"
                    right
                    no-caret
                  >
                    <!-- Ellipsis button to trigger dropdown -->
                    <template #button-content>
                      <i class="fas fa-ellipsis-h dropdown-button"></i>
                    </template>
                    <!-- Dropdown menu options -->
                    <b-dropdown-item @click.stop="unlinkItem(item)">
                      <i class="fas fa-pencil-alt mr-2"></i> Unlink Issue
                    </b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item @click.stop="deleteItem(item)">
                      <i class="fas fa-trash-alt mr-2 text-danger"></i> Delete Issue
                    </b-dropdown-item>
                  </b-dropdown>
                </div>      
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    issueItems: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
    data() {
    return {
      localIssues: this.issueItems,
      showActions: false,
      hoveredItem: null,
    };
  },
  watch: {
    issueItems: {
      immediate: true,
      deep: true,
      handler(newIssues) {
        this.localIssues = newIssues.map(issue => ({
          ...issue,
          issueStatus: { ...issue.issueStatus }
        }));
      }
    },
  },
  computed: {
    issueCount() {
      return this.localIssues?.length || 0;
    },
    totalIssueQty() {
      return this.localIssues?.reduce((total, issue) => total + (issue.issueQty || 0), 0) || 0;
    },
    activeIssueQty() {
      return this.localIssues?.reduce((total, issue) => {
        return issue?.issueStatus?.closedOption !== true ? total + (issue.issueQty || 0) : total;
      }, 0);
    },
  },
  methods: {
    onItemClick(item) {
      this.$emit("issue:click", item);
    },

    unlinkItem(item) {
      this.$emit('issue:unlink', item)
    },

    deleteItem(item) {
      this.$emit('issue:delete', item)
    }
  },
};
</script>

<style scoped>
/* *********************************************
/          Issues List
/  ********************************************* */
.issues-list-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 100%;
}

.issues-list-header, .issues-list-header-active {
  display: flex;
  align-items: center;
  justify-content:space-between;
  font-weight: 500;
  font-size: .8rem;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #e9e9e9;
  border: 1px solid rgb(204, 204, 204);
}

.issues-list-header-active {
  background-color: #ffe599;
  border: 1px solid orange;
}

.issues-list-total-qty {
  display: flex;
  align-items: center;
}

.issues-scrollable-list {
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 0;
  padding-left: 15px;
  padding-right: 15px;
  height: calc(100% - 37px);
}

.no-issues {
  font-size: .9rem;
  font-style: italic;
  color: #888;
  text-align: center;
}

/* *********************************************
/          Issue Item
/  ********************************************* */
.item-container {
  background-color: white;
  margin-bottom: 10px;
  border: 1px solid, #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 1px 1px 3px rgba(199, 213, 225, 0.6); /* Optional: Add a glow effect */
}

.item-container:hover {
  cursor: pointer;
  background-color: #fafafa;
}

.item-container:active {
  transform: translateY(2px); 
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); 
}

.row-style {
  display: flex;
  align-items: center;
}

.col-1 {
  flex: 0 0 8%;
  max-width: 8%;
}

.col-2 {
  flex: 1;
  max-width: 100%;
}

.field-container {
  display: flex;
  align-items: baseline;
  /* border: 1px solid red; */
}

.label-font, .label, .label-right-align {
  color: #999;
  font-size: 12px;
}

.label, .label-right-align{
  margin-right: 5px;

}

.label-right-align {
  text-align: right;
  width: 45px;
}

.field, .fixed-field, .fixed-field-right, .fixed-field-left {
  font-size: 13px;
}

.fixed-field, .fixed-field-right, .fixed-field-left {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fixed-field-right {
  width: 45px;
}

.fixed-field-left {
  width: 140px;
}

.qty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: -12px;
}

.issue-flag-active {
	color: orange;
  font-size: .9rem;
}
.issue-flag-inactive {
	color: #666;
  font-size: .9rem;
}

.more-action-button {
  position: absolute;
  z-index: 1000;
  bottom: -10px;
  right: -11px;
  border: none;
}


.dropdown-button:hover {
  transform: scale(1.1);
  color: rgb(102, 102, 102);
}

.item-disabled {
  pointer-events: none;
  opacity: 0.6;
}
</style>
