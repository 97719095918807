import store from "../store";
import Vue from "vue";
import VueRouter from "vue-router";
import config from "../../config";

// Import route modules
//import purchasedItemRoutes from "./purchasedItem.router.js";
//import issueRoutes from "./issue.router.js";
import amazonRoutes from "./amazon.router.js";

// Import other route components
import Login from "../views/auth/Login.vue";
import Dashboard from "../views/dashboard/Dashboard.vue";
import DashboardSlcPrep from "../views/dashboard/DashboardSlcPrep.vue";
import Admin from "../views/auth/Admin.vue";
import Profile from "../views/auth/Profile.vue";
import About from "../views/auth/About.vue";
import FileUpload from "../views/file-upload/FileUpload.vue";
import NotFound from "../views/navigation/PageNotFound.vue";

Vue.use(VueRouter);

const calcfromDate = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - config.pendingDays);
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  });
  return formattedDate;
};

// Purchased Item routes
// ***************************************************************
import PurchasedItemHome from "../views/purchased-items/PurchasedItemHome.vue";
//import BaseG3List from "../components/BaseG3List.vue";
import PurchasedItem from "../views/purchased-items/PurchasedItem.vue";
// Names used in routes.
const useNamespaceP = "PurchasedItem";
const showUploadButton = true;
const purchasedItemRoutes = [
  {
    path: "/purch-item/receive",
    // Removed route name to avoid warning about default child route
    // name: "PurchasedItemHome",
    component: PurchasedItemHome,
    meta: {
      requiresAuth: true,
      clearHistory: true,
    },
    children: [
      {
        // Receive - Pending
        path: "", // Default child route
        name: "PurchasedReceivePending",
        component: BaseG3List,
        props: {
          namespace: useNamespaceP,
          showUploadButton,
        },
        meta: {
          breadcrumb: {
            text: "Pending Items",
            title: "Pending Purchased Items",
          },
        },
      },
      {
        // Receive - All
        path: "purch-receive--all",
        name: "PurchasedReceiveAll",
        component: BaseG3List,
        props: { namespace: useNamespaceP, showUploadButton },
        meta: {
          breadcrumb: {
            text: "All Purchased Items",
            title: "All Purchased Items",
          },
        },
      },
      {
        // Received > x days
        path: "purch-receive--xxd",
        name: "PurchasedReceiveXXd",
        component: BaseG3List,
        props: { namespace: useNamespaceP, showUploadButton },
        meta: {
          breadcrumb: {
            text: `Pending Items > ${config.pendingDays} Days`,
            // Optional title (else uses text for title)
            title: `Pending Items > ${
              config.pendingDays
            } Days (before ${calcfromDate()})`,
          },
        },
      },
      {
        // Deleted issues
        path: "purch-receive--deleted",
        name: "PurchasedReceiveDeleted",
        component: BaseG3List,
        props: { namespace: useNamespaceP },
        meta: {
          breadcrumb: {
            text: "Deleted Purchased Items",
            title: "Deleted Purchased Items",
          },
        },
      },
      {
        // Purchased Item Detail
        path: "/purchased-item",
        name: "PurchasedItem",
        component: PurchasedItem,
        meta: {
          breadcrumb: {
            text: "Purchased Item Detail",
            title: "",
          },
        },
      },
    ],
  },
];
//                End of PurchasedItem routes
// **************************************************

// Issue route imports
import IssueHome from "../views/issue/IssueHome.vue";
import BaseG3List from "../components/BaseG3List.vue";
import IssueItem from "../views/issue/IssueItem.vue";
const useNamespace = "Issue2";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
      clearHistory: true,
    },
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      clearHistory: true,
    },
  },
  {
    path: "/DashboardSlcPrep",
    name: "DashboardSlcPrep",
    component: DashboardSlcPrep,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      clearHistory: true,
    },
  },
  // Combine routes
  //...issueRoutes,
  ...purchasedItemRoutes,
  ...amazonRoutes,
  // Issue routes begin
  {
    path: "/issue",
    // Removed route name to avoid warning about default child route
    //name: "Issue",
    component: IssueHome,
    meta: {
      requiresAuth: true,
      clearHistory: true,
    },
    children: [
      {
        // Open issues
        path: "", // Default route
        name: "IssueOpen",
        component: BaseG3List,
        props: { namespace: useNamespace },
        meta: {
          breadcrumb: {
            text: "All Open Issues",
            title: "All Open Issues",
          },
        },
      },
      {
        // All issues
        path: "all-issues",
        name: "IssueAll",
        component: BaseG3List,
        props: { namespace: useNamespace },
        meta: {
          breadcrumb: {
            text: "All Issues",
            title: "All Issues",
          },
        },
      },
      {
        // Issues assigned to me
        path: "issue/assigned-to-me",
        name: "IssueAssignedToMe",
        component: BaseG3List,
        props: { namespace: useNamespace },
        meta: {
          breadcrumb: {
            text: "Issues Assigned to Me",
            title: "Issues Assigned to Me",
          },
        },
      },
      {
        // Issues assigned to processor
        path: "issue/assigned-to-processor",
        name: "IssueAssignedToProcessor",
        component: BaseG3List,
        props: { namespace: useNamespace },
        meta: {
          breadcrumb: {
            text: "Issues Assigned to Processor",
            title: "Issues Assigned to Processor",
          },
        },
      },
      {
        // Issues assigned to client
        path: "issue/assigned-to-client",
        name: "IssueAssignedToClient",
        component: BaseG3List,
        props: { namespace: useNamespace },
        meta: {
          breadcrumb: {
            text: "Issues Assigned to Client",
            title: "Issues Assigned to Client",
          },
        },
      },
      {
        // Closed issues
        path: "issue/closed",
        name: "IssueClosed",
        component: BaseG3List,
        props: { namespace: useNamespace },
        meta: {
          breadcrumb: {
            text: "All Closed Issues",
            title: "All Closed Issues",
          },
        },
      },
      {
        // Deleted issues
        path: "issue/deleted",
        name: "IssueDeleted",
        component: BaseG3List,
        props: { namespace: useNamespace },
        meta: {
          breadcrumb: {
            text: "Deleted Issues",
            title: "Deleted Issues",
          },
        },
      },
      {
        // Item detail
        path: "/issue-item",
        name: "IssueItem",
        component: IssueItem,
        meta: {
          breadcrumb: {
            text: "Issue Item",
            title: "",
          },
        },
      },
    ],
  },
  //  End of issue routes
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
      clearHistory: true,
    },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      requiresAuth: true,
      clearHistory: true,
    },
  },
  {
    path: "/file-upload",
    name: "file-upload",
    component: FileUpload,
    meta: { requiresAuth: true },
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  hash: false,
  base: process.env.BASE_URL,
  routes,
});

// If not authenticated, send user to login
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["Session/isAuthenticated"]) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});
// If attempting an admin link, must be admin
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (store.getters["Session/isAdmin"]) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const isNavigatingBack = store.getters["Session/isNavigatingBack"];
  if (to.meta?.clearHistory) {
    store.commit("Session/CLEAR_ROUTE_HISTORY");
  } else if (from.name && !isNavigatingBack) {
    store.commit("Session/PUSH_ROUTE_HISTORY", {
      name: from.name,
      params: from.params,
      query: from.query,
    });
  }
  // Reset isNavigatingBack flag after navigation
  store.commit("Session/SET_NAVIGATION_BACK_FLAG", false);
  next();
});

export default router;
