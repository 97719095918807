<template>
	<b-overlay
		variant="white"
		:show="isLoading && showLoading && showOverlay && useShowLoadingSpinner"
		:style="{ cursor: isLoading ? 'wait' : 'auto' }"
		:opacity="0.0"
		spinner-variant="info"
		class="mx-auto overlay"
	>
		<!-- Table -->
		<div
			id="main-page"
			ref="mainpageRef"
			@keydown.insert.exact="showItemDetail(-1)"
			class="light-border"
		>
			<b-row class="filter-bar"
				:style="{ borderBottom: `2px solid #${accentColor}` }">
				<b-col class="d-flex pl-0 pr-0">
					<BaseFilterBar
						ref="baseFilterBar"
						:namespace="namespace"
						@clear="onClear"
						:searchBoxWidth="searchBoxWidth"
						:fetchOnStart="fetchOnStart"
					/>
					<!-- Action menu to the right -->
					<BaseTableAction
						class="ml-auto"
						:namespace="namespace"
						@action-add="showNewItemDetail()"
						@action-upload="showFileUpload"
						@delete-selected="deleteRecords"
						:showUpload="showUpload"
						:showAdd="showAdd"
						:showRefresh="showRefresh"
						:showOtherActions="true"
						:allowDelete="allowDelete"
						:filter="useRouteName"
					/>
				</b-col>
			<div>
			</div>
			</b-row>
			<div>
			<!-- <b-progress class="" style="height: 3px">
				<b-progress-bar ref="progressBarRef"
					label=""
					:value="listProgressPct"
					max="100"
					variant="info"
				></b-progress-bar>
			</b-progress> -->
			</div>
			<div>
				<div
					class="sticky-table-wrapper"
					id="btableDiv"
					ref="btableDivRef"
					:style="{
						maxHeight: tableHeight,
						overflowY: 'auto',
						position: 'relative'
					}"
					@scroll="debounceUpdateScroll"
				>
					<b-table
						id="btable"
						ref="btableRef"
						:items="items"
						:fields="localFields"
						primary-key="id"
						class="bg-white shadow-sm table-condensed mb-0"
						head-variant="light"
						thead-class="text-smaller sticky-header"
						outlined
						responsive="sm"
						:selectable="selectable"
						small
						hover
						:selectMode="localSelectMode"
						@sort-changed="onSortChanged"
						@row-selected="onRowSelected"
						@row-clicked="onRowClicked"
						@row-contextmenu="onRowContextMenuClicked"
						:sort-by.sync="sortBy"
						:sort-desc.sync="sortOrder"
						no-border-collapse
						:no-local-sorting="noLocalSorting"
						show-empty
						empty-text="There are no records to show"
					>
						<!-- Row index -->
						<template #cell(index)="data">
							<span class="row-index text-right">{{
								rowNumber(data.index)
							}}</span>
						</template>
						<!-- Select column -->
						<template #cell(selected)="{ rowSelected }">
							<template v-if="rowSelected">
								<span aria-hidden="true">&check;</span>
								<span class="sr-only">Selected</span>
							</template>
							<template v-else>
								<span aria-hidden="true">&nbsp;</span>
								<!-- Causes browser scrollbar but didn't when with RestrictedList -->
								<!-- <span class="sr-only">Not selected</span> -->
							</template>
						</template>

						<!-- Select column header -->
						<template #head(selected)="{ label }">
							<template v-if="label">
								<b-button
									variant="outline secondary"
									@click="selectAllRows"
									class="check-header"
									>&check;</b-button
								>
							</template>
						</template>

						<!-- Action column: See detail link (key field called 'action' in localFields array) -->
						<template #cell(action)="row" v-if="items">
							<b-nav class="p-0" :style="`padding: 0`">
								<b-link
									class="p-0"
									:class="{
										'unread-record':
											row.item.unread &&
											row.item.assigneeFamiliarName === user.familiarName,
									}"
									:style="'padding: 0;'"
									@click="showItemDetail(row.item, row.index, $event.target)"
								>
									{{ row.item[keyField] }}
								</b-link>
							</b-nav>
						</template>

						<!-- Amazon link column: amazonLinkField called 'amazonLink' in localFields array) -->
						<template #cell(amazonLink)="row" v-if="items">
							<b-nav class="p-0" :style="`padding: 0`">
								<template v-if="getFieldProperty('amazonLink', 'showLink')">
									<b-link
										id="amazon-link"
										class="p-0"
										:style="`padding: 0`"
										target="_blank"
										:href="
											row.item[amazonUrlField]
												? row.item[amazonUrlField]
												: `https://www.amazon.com/dp/${row.item.asin}?th=1&psc=1`
										"
										rel="noopener noreferrer"
									>
										{{ row.item[amazonLinkField] }}
									</b-link>
								</template>
								<!-- Display plain text if showLink is false -->
								<template v-else>
									{{ row.item[amazonLinkField] }}
								</template>
							</b-nav>
							<!-- <b-tooltip target="amazon-link"
                >Click to see Amazon listing</b-tooltip
              > -->
						</template>

						<!-- Raw html data -->
						<template #cell(rawHtml)="row" v-if="items">
							<div v-html="formatHtml(row.item[rawHtmlField])"></div>
						</template>

						<!-- CRUD action field format (insert, update, delete) -->
						<template #cell(crudAction)="row" v-if="items">
							<div :class="crudActionClass(row.item[crudActionField])">
								{{ row.item[crudActionField] }}
							</div>
						</template>

						<!-- Priority: 1-Urgent is marked danger background -->
						<!-- Assumes field named priority -->
						<template #cell(priorityName)="row" v-if="items">
							<span
								:class="{
									'priority-urgent': row.item.priorityName === '1-Urgent',
									'priority-important': row.item.priorityName === '2-Important',
									'unread-record':
										row.item.unread &&
										row.item.assigneeFamiliarName === user.familiarName,
								}"
							>
								{{ row.item.priorityName }}
							</span>
						</template>

						<!-- Image -->
						<template #cell(image)="row" v-if="items">
							<b-img
								v-if="row.item[imageField]"
								:src="row.item[imageField] || defaultImage"
								class="p-1 d-flex"
								:alt="row.item[imageField] ? 'Item Image' : ''"
								height="40"
								fluid
							></b-img>
						</template>
						<!-- PurchasedItemRecordId column header -->
						<template #head(purchasedItemRecordId)="{ label }">
							<template v-if="label">
								<i
									v-b-tooltip.hover
									title="Issue is linked to purchased item"
									class="fas fa-link link-style text-lighter"
								>
								</i>
							</template>
						</template>
						<!-- PurchasedItemRecordId Cell -->
						<template #cell(purchasedItemRecordId)="row" v-if="items">
							<div v-if="row.item['purchasedItemRecordId']">
								<i 
									v-b-tooltip.hover
									:title="`Purchased item id: ${row.item['purchasedItemRecordId']}`"
									class="fas fa-link link-style text-info"></i>
							</div>
						</template>
						<!-- Attachments column header -->
						<template #head(hasAttachments)="{ label }">
							<template v-if="label">
								<i
									v-b-tooltip.hover
									title="Record has attachments"
									class="fas fa-paperclip paper-clip"
								>
								</i>
							</template>
						</template>
						<!-- Attachments Cell -->
						<template #cell(hasAttachments)="row" v-if="items">
							<div v-if="row.item['hasAttachments']">
								<i class="fas fa-paperclip paper-clip"></i>
							</div>
						</template>
						<!-- Active Issue Column Header -->
						<template #head(hasActiveIssue)="{ label }">
							<template v-if="label">
								<i
									v-b-tooltip.hover
									title="Record has related issues"
									class="fas fa-flag issue-flag-inactive"
								>
								</i>
							</template>
						</template>
						<!-- Active Issue Cell -->
						<template #cell(hasActiveIssue)="row" v-if="items">
							<div v-if="row.item['hasIssueCount'] && row.item['hasActiveIssue'] ">
									<i v-b-tooltip.hover title="Active issue(s)" class="fas fa-flag issue-flag-active"></i>
							</div>
							<div v-else-if="row.item['hasIssueCount']">
									<i v-b-tooltip.hover title="Closed issue(s)" class="fas fa-flag issue-flag-inactive" ></i>
							</div>
						</template>
						<!-- Normal cell -->
						<template #cell()="data"
							><span
								:class="{
									'unread-record':
										data.item.unread === 1 &&
										data.item.assigneeFamiliarName === user.familiarName,
								}"
								>{{ data.value }}</span
							>
						</template>
						<!-- Normal header - tooltip -->
						<template #head()="data">
							<span v-b-tooltip.hover :title="data.field.tooltip">
								{{ data.label }}
							</span>
						</template>
						<!-- Footer -->
						<template slot="custom-foot" v-if="items && items.length > 0">
							<!-- Total -->
							<b-tr
								:style="'font-weight: 700'"
								:class="{ 'footer-row': list.calcs.length > 0 }"
							>
								<template v-for="(field, index) in localFields">
									<b-td
										:key="index"
										v-if="field.showTotal"
										:class="field.class"
									>
										{{
											field.key.toLowerCase().includes("amt")
												? formatCurrency(
														list.calcs[field.key],
														field.decimalDigits === undefined
															? null
															: field.decimalDigits
                    )
												: field.key.toLowerCase().includes("pct")
												? `${(list.calcs[field.key] * 100).toFixed(0)}%`
												: Number(list.calcs[field.key]).toLocaleString()
										}}
									</b-td>
									<b-td :key="index" v-if="!field.showTotal"></b-td>
								</template>
							</b-tr>
						</template>
						<!-- Row detail (for drill-down) -->
						<template #cell(show_details)="row">
							<div class="show-detail-button-container">
								<b-button
									variant="Light"
									size="sm"
									class="b-button show-detail-button"
									@click.prevent="toggleDetails(row.index)"
									v-if="showDrilldown"
								>
									<!-- Turn detail arrow around after detail is displayed -->
									<p class="row-detail-p">
										<b-icon-chevron-down
											:class="[
												'flip-row-detail',
												{ 'flip-row-detail-arrow': row.detailsShowing },
											]"
										/>
									</p>
								</b-button>
							</div>
						</template>
						<template slot="row-details" slot-scope="row">
							<b-card no-body>
								<BaseG3RowDetail
									:namespace="drilldownNamespace"
									:id="row.item?.id"
									:toggle="row.detailShowing"
								/>
							</b-card>
						</template>
					</b-table>
					<!-- Context Menu -->
					<div
						ref="contextMenuRef"
						id="context-menu"
						class="g3-context-menu"
						:style="{ position: 'absolute', top: contextMenuData.y + 'px', left: contextMenuData.x + 'px', zIndex: 9000 }"
						v-show="contextMenuData.visible"
					>
					<div>
						<b-dropdown-item @click="handleMenuClick('open')">
							<i class="fa fa-folder-open mr-2" ></i>Open
						</b-dropdown-item>
						<!-- <b-dropdown-item v-if="localSelectMode === 'single'" @click="handleMenuClick('select-multi')">
							<i class="fa fa-check-double mr-2" ></i>Multiple Select
						</b-dropdown-item> -->
						<!-- <b-dropdown-item v-if="localSelectMode !== 'single'" @click="handleMenuClick('select-single')">
							<i class="fa fa-check-double mr-2" ></i>Single Select
						</b-dropdown-item> -->
						<b-dropdown-item disabled @click="handleMenuClick('export')">
							<i class="fa fa-download mr-2"  ></i>Export
						</b-dropdown-item>
						<b-dropdown-divider />
						<b-dropdown-item class="text-danger" :disabled="$route.name.includes('Deleted')"  @click="handleMenuClick('delete')">
							<i class="fa fa-trash mr-2 text-danger" ></i>Delete
						</b-dropdown-item>
					</div>
				</div>					
				</div>
				<BasePagination :namespace="namespace" class="pt-2" @currentPageChanged="clearTableState(false)"/>
			</div>
		</div>
	</b-overlay>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapState } from "vuex";
//import config from "../../config";
import defaultImage from "../assets/image-not-available.png";
import {
  //  handleRouterPushError,
  formatRawHtml,
	formatCrudAction,
	postMsgBoxOk,
//	handleModalOnOutsideClick,
	handleConfirmChanges2
} from "../utils/component-utils";
import draggableMixin from "../views/common/draggableMixin.js";
import socket from "@/api/socket.js"

export default {
  components: {},
  mixins: [draggableMixin],
  props: {
    namespace: {
      type: String,
      default: ""
    },
    routeName: {
      type: String,
      default: ""
    },
    fields: {
      type: Array,
      default: null
    },
    keyField: { type: String, default: "asin" },
    imageField: { type: String, default: "imageUrl" },
    amazonLinkField: { type: String, default: "asin" },
    amazonUrlField: { type: String, default: "amazonUrl" },
    rawHtmlField: { type: String, default: "changes" },
    crudActionField: { type: String, default: "operation" },
    selectable: { type: Boolean, default: true },
    searchBoxWidth: { type: String, default: "350" },
    showOverlay: { type: Boolean, default: true },
    formTop: { type: Number, default: 0 },
		showAdd: { type: Boolean, default: true },
		showUpload: { type: Boolean, default: false },
    showRefresh: { type: Boolean, default: true },
    showDrilldown: { type: Boolean, default: false },
    drilldownNamespace: { type: String, default: "" },
    allowInsert: { type: Boolean, default: true },
    selectRowShowsDetail: { type: Boolean, default: false },
    allowDelete: { type: Boolean, default: true },
    fetchOnStart: { type: Boolean, default: true },
    showLoadingSpinner: { type: Boolean, default: true }
  },
  data() {
		return {
			tableSelectedRows: null,
      // modalItemData: null,
      forceClose: false,
			isIssueItemOpen: false,
      tableSelected: false,
      window: {
        width: 0,
        height: 0
      },
      tableHeight: "",
      fixSortFlag: false,
      showWaitCursor: false,
      noLocalSorting: true,
			useShowLoadingSpinner: this.$props.showLoadingSpinner,
			// justSavedModalItem: false,
			selectedRowItem: null,
			selectedRowIndex: null,
			selectedRowItemId: 0,
      contextMenuData: {
        visible: false,
        x: 0,
        y: 0,
        item: null,
        index: null,
			},
			contextMenuHeight: null,
			hideContextMenuAfterSec: 15,
			timeoutId: null,
			localSelectMode: 'range',
			listProgressMessage: "",
			listProgressPct: 0,
			tableScrollTop: 0,
		};
  },
	async created() {
		this.handleResize();
  },
async mounted() {
  this.handleResize();
  this.contextMenuHeight = this.$refs.contextMenuRef.offsetHeight;
	const shouldRefreshList = this.$route.params.reload || !this.fromItemDetail || this.creatingNewItem;
	const fromRecId = this.$route.params.fromRecId;
	if (shouldRefreshList) {
		// Refresh list
    this.$store.commit(`${this.namespace}/SET_CURRENT_PAGE`, 1);
		await this.$store.dispatch(`${this.namespace}/fetchAll`);
		// Select record if still in the list
		const index = this.items.findIndex(item => item.id === fromRecId);
		if (index > -1) {
			this.$refs.btableRef.selectRow(index);
		} else if (fromRecId === -1) {
			// New record, just select the first row
			this.$refs.btableRef.selectRow(0);
		}
	} else {
		// Don't refresh list, but restore selected record
    this.restoreTableState(fromRecId);
  }
  window.addEventListener("resize", this.handleResize);
  window.addEventListener("keydown", this.shortcutListener);
  window.addEventListener("click", this.handleClickOutsideContextMenu);
  socket.on("listProgress", (data) => {
    this.listProgressMessage = data.message;
    this.listProgressPct = data.pctProgress * 100;
  });
  this.$store.commit("Session/SET_FROM_ITEM_DETAIL", false);
  this.$store.commit("Session/SET_CREATING_NEW_ITEM", false);
	},

  beforeDestroy() {
		//this.$refs.btableDivRef.removeEventListener('scroll', this.tableScrolling);
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("keydown", this.shortcutListener);
		window.removeEventListener("click", this.handleClickOutsideContextMenu);
		socket.off('listProgress')
  },
	computed: {
		...mapGetters("Session", [
			"isLoading",
			"showLoading",
			"isAuthorized",
			"user",
			"tenantSelectedClient",
			"sidebarHidden",
			"fromItemDetail",
			"creatingNewItem",
		]),
    ...mapState("User", ["users"]),
		
		accentColor() {
			return this.namespace === "PurchasedItem" ? "17A2B8" : "F09600"
		},

		currentPage() {
      return this.$store.state[this.namespace]?.list?.currentPage;
    },
    currentRowIndex() {
      return this.$store.state[this.namespace]?.list?.currentRowIndex;
    },
    currentScrollPosition() {
      return this.$store.state[this.namespace]?.list?.currentScrollPosition;
		},

		useRouteName() {
      return this.$route.name;
    },
    defaultImage() {
      return defaultImage;
    },
    showState() {
      // return this.$store.state[this.namespace];
      return this.$store.getters[`${this.namespace}/showState`];
		},
    localFields() {
      return this.localSelectMode === 'single'
        ? this.fields.filter(field => field.key !== 'selected')
        : this.fields;
    },

    toastDuration() {
      // return this.$store.state[this.namespace];
      return this.$store.getters["Notification/toastDuration"];
    },

		list() {
      return this.$store.getters[`${this.namespace}/list`];
    },
    items() {
      return this.$store.getters[`${this.namespace}/items`];
    },
    lastRefreshed() {
      return this.$store.getters[`${this.namespace}/lastRefreshed`];
    },
    sortBy: {
      async set(sortBy) {
        // If sorted on action or amazonLink field, replace with keyField in props
        let useSortBy =
          sortBy === "action" || sortBy === "amazonLink"
            ? this.keyField
            : sortBy;
        await this.$store.dispatch(`${this.namespace}/listSet`, {
          key: "sortBy",
          value: useSortBy
        });
        //this.fetchAll();
      },
      get() {
        return this.list.sortBy;
      }
    },
    sortOrder: {
      async set(sortOrder) {
        // sortOder set
        await this.$store.dispatch(`${this.namespace}/listSet`, {
          key: "sortOrder",
          value: sortOrder ? "DESC" : "ASC"
        });
        //this.fetchAll();
      },
      get() {
        const order = this.list.sortOrder;
        return order === "DESC" ? true : false;
      }
    },
    selectedItems() {
			const items = this.list.selectedItems;
			if (items.length === 0) {
        this.$refs.btableRef.clearSelected();
      }
      return items;
    }
  },
	watch: {
		async tenantSelectedClient() {
			// Tenant selected client has changed: Refresh page
			await this.fetchAll();
      // this.$nextTick( async () => {
			// 	await this.$store.dispatch(`${this.namespace}/clearFiltersOnly`);
      // });
		},
	},
	methods: {
    updateScroll() {
			const scrollTop = this.$refs.btableDivRef.scrollTop;
      this.$store.commit(`${this.namespace}/SET_CURRENT_SCROLL_POSITION`, scrollTop);
		},
		
		debounceUpdateScroll: _.debounce(function() {
      this.updateScroll();
    }, 300),

		restoreTableState() {
			// Restore the table page, scroll position, and selected row
			if (this.currentScrollPosition && this.$refs.btableDivRef) {
				this.$nextTick(() => {
					this.$refs.btableDivRef.scrollTop = this.currentScrollPosition;
				});
			}
			if (this.currentRowIndex !== undefined && this.items?.[this.currentRowIndex]) {
				this.$refs.btableRef.selectRow(this.currentRowIndex);
			}
		},

		clearTableState(clearPage = true) {
			this.$store.commit(`${this.namespace}/SET_CURRENT_SCROLL_POSITION`, 0);
			if (this.$refs.btableDivRef) {
				this.$nextTick(() => {
					this.$refs.btableDivRef.scrollTop = this.currentScrollPosition;
				});
			}
			this.$store.commit(`${this.namespace}/SET_CURRENT_ROW_INDEX`, null);			
			if (clearPage) {
				this.$store.commit(`${this.namespace}/SET_CURRENT_PAGE`, 1);
			}
		},

    getFieldProperty(key, property) {
      const field = this.localFields.find(field => field.key === key);
      return field ? field[property] : null;
    },
    crudActionClass(action) {
      return formatCrudAction(action);
    },
    formatHtml(rawHtml) {
      return formatRawHtml(rawHtml);
    },
    formatCurrency(amt, decimalDigits) {
      const fmtr = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: decimalDigits !== undefined ? decimalDigits : 2,
        maximumFractionDigits: decimalDigits !== undefined ? decimalDigits : 2
      });
      return fmtr.format(amt);
    },
    shortcutListener(event) {
      if (event.ctrlKey && event.key === "f") {
        // Ctl-f: Search
        this.$refs.baseFilterBar.focusOnSearch();
        // alert(`ctl-f: ${this.$refs["searchField"].name}`);
        event.preventDefault();
      } 
    },
		async fetchAll() {
			this.$nextTick(async () => {
				if (!this.fetchOnStart) return;
				// If we have all rows, local sorting
				if (
					this.list?.items?.length > 0 &&
					this.list?.items?.length === this.list?.totalRows
				) {
					this.noLocalSorting = false;
					this.clearTableState();
					// 2/9/22: Fix issue where not updating current item in list if all items fit on one page
					await this.$store.dispatch(
						`${this.namespace}/fetchAll`,
						this.useRouteName
					);
				} else {
					// Server-side sorting
					this.noLocalSorting = true;
					this.clearTableState();
					await this.$store.dispatch(
						`${this.namespace}/fetchAll`,
						this.useRouteName
					);
				}
				// Call handleResize or other layout adjustments
				this.handleResize();
				this.reSelectRow();
			});
		},
		reSelectRow() {
			// Refresh selected row
			if (this.selectedRowItemId > 0) {
				this.selectedRowIndex = this.items?.findIndex(item => item?.id === this.selectedRowItemId);
				if (this.selectedRowIndex !== -1) {
					this.$refs.btableRef.selectRow(this.selectedRowIndex);
				}
			}
		},
		reSelectRowByIndex(index) {
			if (index !== -1) {
				this.$refs.btableRef.selectRow(index);
			}
		},

    rowNumber(index) {
      return (
        this.list.pageSize * this.list.currentPage -
        this.list.pageSize +
        index +
        1
      );
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      this.getTableHeight();
    },

    getTableHeight() {
      const tableTop = parseInt(
        this.$refs.btableDivRef?.getBoundingClientRect()?.top
      );
      const top = parseInt(this.$refs.mainpageRef?.getBoundingClientRect()?.top);
      const rawHeight =
        this.window.height -
        (this.formTop > 0 ? this.formTop + 100 : tableTop) -
        top;
      const roundedHeight = Math.ceil(rawHeight / 10) * 10;
			this.tableHeight = `${roundedHeight + 30}px`;
			if (this.$refs.btableRef) {
				this.$refs.btableRef.offsetHeight = this.tableHeight;
			}
    },

    async onSortChanged(ctx) {
      //Sort changed on Action column:
      // Fix issue where sort indicator doesn't show ascending
      // the first time we click on the action column sort
      if (this.fixSortFlag) {
        this.fetchAll();
        return;
      }
      if (ctx.sortBy === "action" || ctx.sortBy === "amazonLink") {
        // Known issue as of 2/1/22: amazonLink doesn't show up arrow when first sorting ascending;
        // however, an action column does.
        this.fixSortFlag = true;
        try {
          const el = document.querySelectorAll(".col-action");
          const attr = el[0]?.getAttribute("aria-sort");
          if (
            ctx.sortDesc === false &&
            (attr === null || attr !== "ascending")
          ) {
            // Set sort indicator up
            await el[0].setAttribute("aria-sort", "ascending");
            // this.sortOrder = false;
          } else if (attr === "ascending") {
            // Set sort indicator down
            this.sortOrder = true;
            await el[0].setAttribute("aria-sort", "descending");
          }
        } finally {
          this.fixSortFlag = false;
        }
      } else {
        // Clear action sort arrow
        const el = document.querySelectorAll(".col-action");
        //const attr = el[0]?.getAttribute("aria-sort");
        await el[0]?.setAttribute("aria-sort", "none");
        this.sortOrder = ctx.sortDesc;
        this.sortBy = ctx.sortBy;
      }
      this.fetchAll();
    },

		async onRowSelected(items) {
      await this.$store.dispatch(`${this.namespace}/listSet`, {
        key: "selectedItems",
        value: items
			});
    },
		// Left mouse button row click
		async onRowClicked(item, index, event) {
			const clickedCell = event?.target?.closest('td');
			if (clickedCell) {
				const rowCells = clickedCell?.parentElement.children;
				const columnIndex = Array.from(rowCells).indexOf(clickedCell);
				// If user clicked on selected (checkmark cell), do nothing
				if (this.localFields[columnIndex]?.key === 'selected') {
					return;
				}
			}
			this.selectedRowItemId = item?.id
			this.selectedRowIndex = index;
			this.selectedRowId = index;
      this.$store.commit(`${this.namespace}/SET_CURRENT_ROW_INDEX`, index);
      await this.showItemDetail(item);
    },

		handleClickOutsideContextMenu() {
			this.contextMenuData.visible = false;
		},

		// Right mouse button row click
		async onRowContextMenuClicked(item, index, event) {
			event.preventDefault();
			// If this row is not yet selected, clear all selected
			if (this.selectedItems && !this.selectedItems?.find(x => x.id === item.id)) {
				this.$refs.btableRef.clearSelected();
			}
			// Clear close timer
			if (this.timeoutId) {
				clearTimeout(this.timeoutId);
			}
			// Select row
			this.selectedRowItemId = item.id;
			this.selectedItem = item;
			this.selectedRowIndex = index;
			this.$refs.btableRef.selectRow(this.selectedRowIndex);
			// Show context menu
			const sidebarWidth = 240;
			const offsetX = this.sidebarHidden ? 0 : -sidebarWidth;
			const offsetY = -135;
			const menuWidth = 215;
			const menuHeight = 200;
			let x = event.pageX + offsetX;
			let y = event.pageY + offsetY + this.tableScrollTop;
			// Ensure the context menu doesn't overflow the right edge of the window
			if (x + menuWidth > this.window.width + offsetX) {
				x = this.window.width - menuWidth + offsetX;
			}
			// Ensure the context menu doesn't overflow the bottom edge
			if (y + menuHeight > window.innerHeight + offsetY + this.tableScrollTop || 0) {
				y = window.innerHeight - menuHeight + offsetY  + this.tableScrollTop || 0;
			}
			this.contextMenuData = {
				visible: true,
				x,
				y,
				item,
				index,
			};
			// Hide the context menu after 20 seconds
			this.timeoutId = setTimeout(() => {
				this.contextMenuData.visible = false;
			}, this.hideContextMenuAfterSec * 1000);
		},

		handleMenuClick(action) {
			switch(action) {
				case "open":
					this.onRowClicked(this.selectedItem);
					break;
				case 'select-multi':
					this.localSelectMode = 'range';
					break;
				case 'select-single':
					this.localSelectMode = 'single';
					break;
				case 'export':
					break;
				case 'delete':
					this.deleteRecords();
					break;
				default:
					console.warn('Unknown action: ', action);
				}
			this.contextMenuData.visible = false;
    },

		async deleteRecords() {
			const count = this.selectedItems.length;
			const selectedIndex = this.selectedRowIndex;
			// Validate we can delete
			const validationResult = await this.$store.dispatch(`${this.namespace}/itemsDeleteValidate`, this.selectedItems);
			if (validationResult) {
				postMsgBoxOk(this, "Delete Issues", validationResult, 'warning');
				return;
			}
			// Confirm
			const message = `Are you sure you want to delete ${count > 1 ? "these" : "this"} ${count} selected ${count > 1 ? "items" : "item"}?`
			const answer = await handleConfirmChanges2({ thisObj: this, message, okVariant: 'danger' })
			if (!answer) return;
			// Delete
			await this.$store.dispatch(`${this.namespace}/itemsDelete`, this.selectedItems);
			const toastMsg = `Successfully deleted ${count} record${count > 1 ? 's' : ''}`
			this.$store.dispatch("Notification/toastMsgAdd", toastMsg, { root: true });
			await this.fetchAll();
			this.reSelectRowByIndex(selectedIndex);
			setTimeout(() => {
				this.listProgressPct = 0;
				this.listProgressMessage = ""
			}, this.toastDuration);
		},
		// User clicked Clear button
		onClear() {
			this.clearSelected();
			this.clearTableState();
		},
		
		clearSelected() {
      this.$refs.btableRef.clearSelected();
			this.tableSelected = false;
			this.selectedRowItemId = 0;
    },

    selectAllRows() {
			if (this.tableSelected) {
        this.$refs.btableRef.clearSelected();
      } else {
        this.$refs.btableRef.selectAllRows();
      }
      this.tableSelected = !this.tableSelected;
    },
		async showFileUpload() {
			const cId = this.user?.tenantSelectedClient?.clientId
			if (cId && cId === "0"){
				// Tenant user must select client
				this.clientSelectMsgBox("File Upload");
				return;
			}
			this.$router.push({
				path: "/file-upload",
				query: {
					namespace: this.namespace
				}
			})
		},
		clientSelectMsgBox(activity) {
			const msg = `${activity} requires a client context. Please select a specific client then try again.

					As a processor, you currently have "All clients" selected. On the main navigation bar at the top, select one of your clients.`;
			postMsgBoxOk(this, `${activity} Client Required`, msg)
			return;
		},
		toggleDetails(index) {
      this.$store.commit(`${this.namespace}/TOGGLE_SHOW_DETAILS`, { index });
      this.$store.commit(`${this.drilldownNamespace}/TOGGLE_LIST_SET`, {
        key: "toggleDrilldown"
      });
    },

		// * ******************************************************** */
    // *           SHOW/HIDE DETAIL
		// * ******************************************************** */
		async showNewItemDetail() {
			const newItem = {
				id: -1,
				clientId: this.user?.scope === "tenant" ? this.user?.tenantSelectedClient?.clientId : this.user?.clientId,
				clientName: this.user?.scope === "tenant" ? this.user?.tenantSelectedClient?.clientName : this.user?.clientName,
				tenantId: this.user?.tenantId
			}
			const cId = this.user?.tenantSelectedClient?.clientId
			if (!cId || cId === "0"
			) {
				// Tenant user must select client
				this.clientSelectMsgBox("Create Item Detail");
				return;
			}
			// Hide spinner while loading detail
			this.useShowLoadingSpinner = false;
			this.$store.commit("Session/SET_CREATING_NEW_ITEM", true);
			await this.showItemDetail(newItem);
			setTimeout(() => {
				this.useShowLoadingSpinner = true;
			}, 1000);
		},

		async showItemDetail(item) {
			this.selectedRowItemId = item.id
			const originatingRoute = this.$route.name;
			this.$store.commit("Session/SET_FROM_ITEM_DETAIL", false);
			this.$store.dispatch(`${this.namespace}/currentItemInitialize`, item);
			let objRoute = {};
			let name = "";
			switch (this.namespace) {
				case 'PurchasedItem':
					name = "PurchasedItem";
					objRoute = {
						name,
						text: "Purchased Item Detail", 	// Breadcrumb trail
						title: "",											// Top page title
					}
					// Route
					this.$router.push({
						params: { id: item.id },
						name,
						query: {
							from: originatingRoute,
							callingClientId: item.clientId,
							callingTenantId: item.tenantId,
						}
					});
					break;
				case 'Issue2':
					name = "IssueItem"
					objRoute = {
						name,
						text: "Issue Item Detail",			// Breadcrumb trail
						title: "", 											// Top page title
					}
					// Route
					this.$router.push({
						name: "IssueItem",
						params: { id: item.id },
						query: {
							from: originatingRoute,
							callingClientId: item.clientId,
							callingTenantId: item.tenantId,
						}
					});
					break;
				default:
					throw new Error("Unknown namespace");
			}
      this.setBreadcrumb(objRoute);
		},
    setBreadcrumb(objRoute) {
			//const { text, name, title } = objRoute
      this.$store.commit("Session/ADD_BREADCRUMB", objRoute );
    },

  }
};

</script>


<style lang="css" >
/* Note that I turned off scope on CSS to get these styles to work on b-table
/* General list styles */

.red-border {
	border: 1px solid red;
}

.sticky-table-wrapper {
	position: relative;
	overflow-y: auto;
}

.sticky-header {
	position: sticky;
	top: 0;
	z-index: 2;
	background-color: white;
}

.light-border {
	outline: 1px solid #ddd;
}

.row-index {
	color: #999;
}

.filter-bar {
	background-color: RGB(248, 248, 248);
	margin-left: 0px;
	margin-right: 0px;
	padding-left: 0px;
	padding-right: 0px;
}

.table-condensed {
	font-size: 0.8em !important;
}
#main-page {
	/* padding-left: 250px;
  padding-right: 150px; */
}
@media only screen and (max-width: 2000px) {
	#main-page {
		padding-right: 0;
	}
}

.unread-record {
	font-weight: 600;
}

.col-emphasis {
	color: rgb(39, 111, 170);
	font-weight: 700;
}
.col-center {
	text-align: center;
}
.col-right {
	text-align: right;
}
.col-bold {
	font-weight: 700;
}
.col-30 {
	width: 50px;
}
.col-40 {
	width: 50px;
}
.col-50 {
	width: 50px;
}
.col-60 {
	width: 60px;
}
.col-70 {
	width: 70px;
}
.col-80 {
	width: 80px;
}
.col-90 {
	width: 90px;
}
.col-100 {
	width: 100px;
}
.col-110 {
	width: 110px;
}
.col-120 {
	width: 120px;
}
.col-130 {
	width: 130px;
}
.col-140 {
	width: 140px;
}
.col-150 {
	width: 150px;
}
.col-180 {
	width: 180px;
}
.col-200 {
	min-width: 180px;
}
.col-210 {
	width: 210px;
}
.col-220 {
	width: 220px;
}
.col-230 {
	width: 230px;
}
.col-240 {
	width: 240px;
}
.col-250 {
	width: 250px;
}
.col-300 {
	width: 300px;
}
.col-350 {
	max-width: 350px;
}
.col-400 {
	width: 400px;
}
.col-450 {
	width: 450px;
}
.col-500 {
	width: 500px;
}
.col-5-pct {
	width: 5%;
}
.col-7-pct {
	width: 7%;
}
.col-10-pct {
	width: 10%;
}
.col-12-pct {
	width: 12%;
}
.col-15-pct {
	width: 15%;
}
.col-16-pct {
	width: 16%;
}
.col-17-pct {
	width: 17%;
}
.col-18-pct {
	width: 18%;
}
.col-20-pct {
	width: 20%;
}
.col-30-pct {
	width: 30%;
}
.col-40-pct {
	width: 40%;
}
.col-50-pct {
	width: 50%;
}
.col-60-pct {
	width: 60%;
}
.col-70-pct {
	width: 70%;
}
.col-80-pct {
	width: 80%;
}
.col-90-pct {
	width: 90%;
}
.col-light-text {
  color: #777;
}

@media only screen and (max-width: 2000px) {
	.col-elipsis {
		/* white-space: nowrap;
    max-width: 350px;
    color: blue; */
	}
}
@media only screen and (max-width: 1700px) {
	.col-elipsis {
		/* white-space: unset;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: red; */
	}
}
@media only screen and (max-width: 768px) {
	.col-elipsis {
    max-width: 150px;
	}
}
.col-elipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: unset;
	padding-right: 0;
	padding-left: 0;
}

.col-red {
	color: red;
	font-weight: 600;
}

.col-warning {
	color: orange;
	font-weight: 600;
}

.col-elipsis-30 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 30px;
}

.col-elipsis-40 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 40px;
}
.col-elipsis-50 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 50px;
}
.col-elipsis-300 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 300px;
}
.col-elipsis-400 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 400px;
}
.col-elipsis-425 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 500px;
}
.col-elipsis-500 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 500px;
}
.col-350 {
	width: 350px;
}
.priority-urgent {
	background-color: #f1b0b7;
	padding: 5px;
}
.priority-important {
	background-color: #f1d3b0;
	padding: 5px;
}
.footer-row > td {
	border-top: 2px solid #ddd !important;
}
.paper-clip {
	color: #999;
	font-size: 1.1rem;
	transform: rotate(-45deg);
}
.link-style {
	font-size: 1.1rem;
}
.link-style {
	font-size: 1.1rem;
}
.showWaitClass {
	cursor: wait !important;
}
.show-detail-button-container {
	background-color: transparent;
	margin-bottom: -10px;
	padding-bottom: -10px;
}
.row-detail-p {
	margin-bottom: 0px;
	font-size: 0.8em;
	font-weight: 700;
}

.show-detail-button {
	background-color: transparent;
	border: none;
	margin: 0px;
	padding-bottom: 0px;
	transition: 0.2s;
}
.show-detail-button:hover {
	background-color: transparent;
	transform: scale(1, 1);
}

.text-lighter {
	color: #777;
}
.flip-row-detail {
	-moz-transition: all 0.1s linear;
	-webkit-transition: all 0.1s linear;
	transition: all 0.1s linear;
}
.flip-row-detail-arrow {
	-moz-transform: rotate(180deg) scale(-1, 1);
	-webkit-transform: rotate(180deg) scale(-1, 1);
	-o-transform: rotate(180deg) scale(-1, 1);
	-ms-transform: rotate(180deg) scale(-1, 1);
	transform: scale(1, -1);
}
.check-header {
	font-size: 10px;
	font-weight: bold;
	padding: 0;
}
.issue-flag-active {
	color: orange;
}
.issue-flag-inactive {
	color: #666;
}

.g3-context-menu {
	background-color: white;
	z-index: 1000;
	border: 1px solid #ccc;
	border-radius: 5px;
  list-style-type: none;
  padding-left: 0;
	padding-right: 0;
	padding-top: 10px;
	padding-bottom: 10px;
	width: 180px;
	box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
}

/* Optional: Style for dropdown items */
.b-dropdown-item {
  padding: 10px 15px;
  cursor: pointer;
}

.b-dropdown-item:hover {
  background-color: #f1f1f1;  /* Add hover effect */
}

.context-delete{
	color: red;
}
.context-delete-icon{
	color:red;

}

</style>


