<template>
  <div class="menu-wrapper" :style="cssVars">
    <!-- Other Lists dropdown -->
    <div class="sub-menu ml-3">
      <div class="menu-item">
        <div class="menu-label" @click="toggleMenu">
          <span class="menu-label-name">{{headingLabel}}</span>
          <b-icon 
            class="ml-3 chevron" 
            :class="{ expanded: isExpanded }"
            icon="chevron-right" />
        </div>
        <transition name="slide-fade">
          <div v-show="isExpanded" class="menu-items"> 
            <!-- Inserted menu items (or anything) from outside -->
            <!-- <b-nav-item
              ref="issue-deleted"
              exact-active-class="nav-item-active"
              :to="{ name: 'IssueDeleted' }"
              @click="onNavItemClick"
            >
              Issues - Deleted
            </b-nav-item> -->
            <slot></slot>
          </div>
        </transition>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    headingLabel: {type: String, default: "Other Lists"},
    cssVars: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  /*
    Pass in style from outsite as prop
        wrapperStyles: {
        backgroundColor: 'lightgray',
        padding: '10px',
        border: '1px solid red'
      }
*/
  methods: {
    toggleMenu() {
      this.isExpanded = !this.isExpanded;
    },
  }
};
</script>

<style scoped>
.menu-wrapper {
  list-style: none;
  padding: 0;
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-item {
  margin: 5px 0;
  padding-left: 0 !important;
}

.menu-items {
  margin-left: 0;
  padding-left: 0 !important;
}

.menu-label {
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
}

.menu-label-name {
  text-transform: uppercase;
  font-size: .8rem;
  font-weight: 800;
  color: steelblue;
}

.chevron {
  transition: transform 0.3s ease;
  transform: rotate(0deg);
}

.chevron.expanded {
  transform: rotate(90deg);
}

/* Animation for submenu (slide and fade effect) */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-enter-from {
  opacity: 0;
  transform: translateY(-10px); /* Slide in from above */
}

.slide-fade-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.slide-fade-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.slide-fade-leave-to {
  opacity: .1;
  transform: translateY(-10px); /* Slide out above */
}

</style>