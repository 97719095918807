<template>
  <div class="button-container">
    <button
      @click="handleClick"
      :class="['info-button', {'animate-button': isAnimated}]"
      @click.stop.prevent
      :tabindex="-1"
      :disabled=disabled
    >
      <!-- <i :class="['fa-xs', icon]"></i> -->
      <i :class="['fa-xs', icon]"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    icon: {type: String, default: "fas fa-info"},
    buttonType: {type: String, default: "info"}, // Valid: info, down   
    disabled: {type: Boolean, default: false},
  },
  data() {
    return {
      isAnimated: false,
      currentIcon: this.icon,
    };
  },
  watch: {
    icon(newIcon) {
      console.log("Icon changed to:", newIcon);
      // Perform any additional logic if needed
    },
  },
  computed: {
    // cssVars() {
    //   const positionStyles = {
    //     // Note to make this work, must specify ":style = 'cssVars'" in top div element above.
    //     "--width": this.width,
    //     "--left": this.left,
    //     "--right": this.right,
    //   };
    //   // Only apply top or bottom, but not both
    //   if (this.top) {
    //     positionStyles["--top"] = this.top || "40px";
    //   } else {
    //     positionStyles["--bottom"] = this.bottom;
    //   }
    //   return positionStyles;
    // },
  },
  methods: {
    handleClick() {
      this.$emit("clicked")
//      this.triggerTemporaryIconChange();
    },
    triggerTemporaryIconChange() {
      const originalIcon = this.currentIcon;
      this.currentIcon = "";
      this.$nextTick(() => {
      this.currentIcon = "fas fa-check";
      });
      setTimeout(() => {
        this.$nextTick(() => {
        this.currentIcon = originalIcon;
    });
    }, 3000);
    },
      triggerAnimation() {
      this.isAnimated = true;
      setTimeout(() => {
        this.isAnimated = false;
      }, 1000); // Adjust duration as needed
    },
  },
  
};
</script>

<style scoped>
.button-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.info-button {
  display: flex;
  align-items: center; /* Center icon vertically */
  justify-content: center; /* Center icon horizontally */
  background-color: transparent;
  color: rgb(129, 170, 183);
  height: 24px; /* Adjust size for better icon placement */
  width: 24px; /* Match height for a perfect circle */
  border: none;
  border-radius: 50%; /* Make the button circular */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: box-shadow 0.3s, transform 0.3s; /* Add smooth animation for hover effect */
}

.info-button:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Highlight hover shadow */
  transform: scale(1.1); /* Slight zoom effect */
}

.info-button:active {
  transform: translateY(2px); 
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); 
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-3px);
  }

  40% {
    transform: translateX(3px);
  }

  60% {
    transform: translateX(-3px);
  }

  80% {
    transform: translateX(3px);
  }

  100% {
    transform: translateX(0);
  }
}

.animate-button {
  animation: shake .25s ease-in-out 2; /* Shake for 0.5 seconds, repeat 3 times */
  animation-iteration-count: infinite
}

i {
  display: inline-block !important;
}

.green-icon {
  color: green;
}
</style>
