<template class="template">
  <b-overlay
    variant="white"
    :show="isLoading"
    :opacity="0.0"
    spinner-variant="light"
    spinner-type="none"
    class="mx-auto sidebar-home-overlay"
  >
    <div class="sidebar-wrapper d-flex main-container" >
      <!-- Show sidebar -->
      <b-button
        size="sm"
        class="sidebar-unhide-button"
        variant="light"
        v-if="sidebarHidden"
        @click.prevent="unhideSidebar"
      >
        <i class="fas fa-thumbtack unpin-icon sidebar-hide-icon"></i>
      </b-button>
      <!-- Side nav for Purcahsed Items Receive -->
      <div
        class="sidebar-body p-1 sidebar-border"
        :class="{ 'sidebar-hidden': sidebarHidden }"
      >
        <b-nav vertical class="sidebar-nav sidebar">
          <!-- Hide sidebar button -->
          <b-button
            size="sm"
            variant="light"
            class="
              ml-auto
              mb-0
              sidebar-hide-button 
            "
            @click.prevent="hideSidebar"
          >
            <i class="fas fa-thumbtack sidebar-hide-icon"></i>
          </b-button>
          <!-- Header -->
          <div class="pl-3 mt-4">
            <h5>Purchased Items</h5>
          </div>
          <hr class="mt-1" />
          <!-- Pending Receive List -->
          <b-nav-item
            ref="rec-oar"
            :class="{'nav-item-active': isActive('PurchasedReceivePending')}"
            @click.prevent="onNavClick('PurchasedReceivePending')"
          >
            Pending Items</b-nav-item
          >
          <!-- Pending >xx days -->
          <b-nav-item
            ref="rec-oar"
            :class="{'nav-item-active': isActive('PurchasedReceiveXXd')}"
            @click.prevent="onNavClick('PurchasedReceiveXXd')"
          >
            Pending Items > {{ pendingDays }} days</b-nav-item
          >
          <hr />
          <!-- Received All -->
          <b-nav-item
            ref="rec-oar"
            :class="{'nav-item-active': isActive('PurchasedReceiveAll')}"
            @click.prevent="onNavClick('PurchasedReceiveAll')"
          >
            All Purchased Items</b-nav-item
          >
          <hr />
          <BaseExpandableMenu >
            <!-- Deleted issue list -->
            <b-nav-item
              ref="issue-deleted"
              :class="{'nav-item-active': isActive('PurchasedReceiveDeleted')}"
              @click.prevent="onNavClick('PurchasedReceiveDeleted')"
            >
              Deleted Purchased Items
            </b-nav-item>
          </BaseExpandableMenu>
        </b-nav>
      </div>
      <div class="main-content-overlay" @click="hideSidebar" ></div>
      <!-- Main Content Area -->
      <div id="purchasedItemMain" class="main-content p-0 flex-grow-1 m-0" >
        <BaseBreadcrumb class="border-left"/>
        <router-view></router-view> 
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { mapGetters } from "vuex";
import config from "../../../config"
import {
  handleNavClick
} from "../../utils/component-utils";

// Bootstrap sidebar menu
export default {
  components: {},
  props: {},
  data() {
    return {
      namespace: "PurchasedItem",
      refreshTooltip: "Click to refresh summary",
      pendingDays: config.pendingDays,
    };
  },
  async created() {},
  mounted() {
  },
  computed: {
    ...mapGetters({
      isLoading: "Session/isLoading"
    }),
    ...mapGetters("Session", ["isAuthorized",
      "breadcrumbTrail"]),
    config() {
      return config;
    },
    totalPending: function() {
      return this.pendingAging
        .reduce((acc, curr) => acc + Number(curr.pendingQty), 0)
        .toLocaleString();
    },
    sidebarHidden: {
      set(sidebarHidden) {
        this.$store.dispatch("Session/sidebarHidden", sidebarHidden);
      },
      get() {
        return this.$store.state["Session"].sidebarHidden;
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
    vm.handleBreadcrumbs(to, from);
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.handleBreadcrumbs(to, from);
    next();
  },

  methods: {

    handleBreadcrumbs(to) {
      const breadcrumbTrail = this.breadcrumbTrail || [];
      const lastBreadcrumb = breadcrumbTrail[breadcrumbTrail.length - 1]?.name;
      const firstBreadcrumb = breadcrumbTrail[0]?.name
      if (lastBreadcrumb === "IssueItem" && firstBreadcrumb?.startsWith("Issue")) {
        // Routing to PI detail: Issue List > Issue Detail > PI Detail: Add last route
        this.$store.commit("Session/ADD_BREADCRUMB", {
          name: to.name,
          text: to.meta?.breadcrumb?.text,
          title: to.meta?.breadcrumb?.title
        });
      } else if ((lastBreadcrumb === "IssueItem" && firstBreadcrumb?.startsWith("Purchased"))) {
        // Routing back to PI Detail: PI List > PI Detail > Issue Detail > back to PI Detail: Remove last route
        const lastIndex = breadcrumbTrail.map(item => item.name).lastIndexOf("IssueItem");
        this.$store.commit("Session/REMOVE_LAST_BREADCRUMB", lastIndex);
      } else if (lastBreadcrumb === "PurchasedItem") {
        // Routing back from PI detail: Remove last
        const lastIndex = breadcrumbTrail.map(item => item.name).lastIndexOf("PurchasedItem");
        this.$store.commit("Session/REMOVE_LAST_BREADCRUMB", lastIndex);
      } 
    },

    onNavClick(name) {
      handleNavClick(this, name)
    },

    hideSidebar() {
      this.sidebarHidden = true;
      document.querySelector('.main-content-overlay').style.display = 'none';
    },

    isActive(routeName) {
      return this.$route.name === routeName;
    },

    async refreshRs() {
      await this.$store.dispatch(`${this.namespace}/fetchReceiveSummary`);
    },

    async refreshPa() {
      await this.$store.dispatch(`${this.namespace}/fetchPendingAging`);
    },

    setTitle(text) {
      this.$store.dispatch(`Session/setSubNav`, text);
    },

    unhideSidebar() {
      this.sidebarHidden = false;
      document.querySelector('.main-content-overlay').style.display = 'block';
    }
  }
};
</script>

<style lang="css" scoped >

.red-border {
  border: 1px solid red;
}

.main-container {
  height: calc(100vh - 48px);
}

</style>