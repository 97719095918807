var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.cssVars)},[_c('b-form-group',{staticClass:"label",class:{
      'form-group--error': _vm.error,
      'form-group--compact': _vm.compact,
      'form-group--compact--readonly': _vm.compact && _vm.readonly,
      'text-bold': _vm.boldLabel,
    },attrs:{"id":_vm.id,"label":_vm.label,"label-for":`${_vm.id}-input`,"label-cols":_vm.labelCols,"description":_vm.error ? '' : _vm.description,"label-align":_vm.labelAlign,"label-size":_vm.size,"label-class":_vm.labelClass}},[_c('div',{staticClass:"input-container",class:{
      focused: _vm.focused, 'd-flex': !_vm.error}},[_c('b-form-input',_vm._g(_vm._b({directives:[{name:"uppercase",rawName:"v-uppercase",value:(_vm.uppercase),expression:"uppercase"}],staticClass:"custom-input",class:{
          'text-bold': _vm.boldValue,
          'right-align': _vm.rightAlignValue,
          'input-readonly': _vm.readonly,
          'input-plaintext': _vm.plaintext
        },style:(`width: ${_vm.textFieldWidth}px; margin-left: ${_vm.marginLeftValue}px`),attrs:{"autofocus":_vm.autofocus,"id":`${_vm.id}-input`,"name":`${_vm.id}-input`,"type":_vm.type,"value":_vm.value==='' ? '' : _vm.value,"autocomplete":_vm.autocomplete,"plaintext":_vm.plaintext || _vm.readonly,"placeholder":_vm.placeholder,"readonly":_vm.readonly,"size":_vm.size,"state":_vm.state,"aria-describedby":`${_vm.id}-live-feedback`},on:{"change":function($event){return _vm.updateValue(_vm.id, $event)},"input":function($event){return _vm.touchValue(_vm.id, $event)}},nativeOn:{"focus":function($event){return _vm.handleFocus.apply(null, arguments)},"blur":function($event){return _vm.handleBlur.apply(null, arguments)}}},'b-form-input',_vm.$attrs,false),_vm.$listeners)),_c('b-form-invalid-feedback',{attrs:{"id":`${_vm.id}-live-feedback`}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]),(!_vm.error)?_c('button',{staticClass:"info-button",attrs:{"id":"copy-button","tabindex":-1,"disabled":_vm.isDisabled},on:{"click":[_vm.handleCopyClick,function($event){$event.stopPropagation();$event.preventDefault();}]}},[_c('i',{key:_vm.confirmCopy ? 'showCheck' : 'showCopy',staticClass:"fa-xs fa fa-clone",class:{confirmCopy: 'confirm-copy'}})]):_vm._e(),_c('b-tooltip',{ref:"copyTooltip",attrs:{"target":"copy-button","delay":"1000","triggers":"hover","variant":"secondary"}},[_vm._v("Copy to clipboard")]),(_vm.showCopyCheck)?_c('span',{staticClass:"check-container"},[_c('i',{staticClass:"fa fa-check green-check"})]):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }