<template>
  <div :style = "cssVars">
    <b-form-group
      :id="id"
      :label="label"
      :label-for="`${id}-input`"
      :label-cols="labelCols"
      :description="error ? '' : description"
      :label-align="labelAlign"
      :label-size="size"
      :label-class="labelClass"
      class="label"
      :class="{
        'form-group--error': error,
        'form-group--compact': compact,
        'form-group--compact--readonly': compact && readonly,
        'text-bold': boldLabel,
      }"
    >
      <div class="input-container " :class="{
        focused: focused, 'd-flex': !error}">
        <b-form-input
          :class="{
            'text-bold': boldValue,
            'right-align': rightAlignValue,
            'input-readonly': readonly,
            'input-plaintext': plaintext
          }"
          class="custom-input"
          :autofocus="autofocus"
          :id="`${id}-input`"
          :name="`${id}-input`"
          :type="type"
          :value="value==='' ? '' : value"
          @change="updateValue(id, $event)"
          @input="touchValue(id, $event)"
          @focus.native="handleFocus"
          @blur.native="handleBlur"
          v-bind="$attrs"
          v-uppercase="uppercase"
          :autocomplete="autocomplete"
          :plaintext="plaintext || readonly"
          :placeholder="placeholder"
          :readonly="readonly"
          :style="`width: ${textFieldWidth}px; margin-left: ${marginLeftValue}px`"
          :size="size"
          :state="state"
          :aria-describedby="`${id}-live-feedback`"
          v-on="$listeners"
        ></b-form-input>
        <b-form-invalid-feedback :id="`${id}-live-feedback`">
          {{ errorMsg }}
        </b-form-invalid-feedback>
        <!-- Copy Button -->
          <button
          v-if="!error"
            id="copy-button"
            @click="handleCopyClick"
            class="info-button"
            @click.stop.prevent
            :tabindex="-1"
            :disabled="isDisabled"
            >
            <i :key="confirmCopy ? 'showCheck' : 'showCopy'" 
              class="fa-xs fa fa-clone"  
              :class="{confirmCopy: 'confirm-copy'}"></i>
          </button>
          <!-- Copy Tooltip -->
          <b-tooltip
            target="copy-button"
            ref="copyTooltip"
            delay="1000"
            triggers="hover"
            variant="secondary"
            >Copy to clipboard</b-tooltip
          >
          <!-- Green checkmark -->
          <span v-if="showCopyCheck" class="check-container">
            <i class="fa fa-check green-check"></i>
          </span>
          <!-- Action buttons
          <div v-if=focused class="action-buttons d-flex">
            <button class="info-button action-buttons-cancel " @click="cancelInput">
              <font-awesome-icon :icon="['fas', 'xmark']" />
            </button>
            <button class="info-button action-buttons-ok" @click="submitInput">
              <font-awesome-icon :icon="['fas', 'check']" />
            </button>
          </div> -->
      </div>
    </b-form-group>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    autocomplete: { type: String, default: "off" },
    autofocus: { type: Boolean, default: false },
    boldValue: { type: Boolean, default: false },
    boldLabel: { type: Boolean, default: false },
    classField: { type: String, default: "" },
    compact: { type: Boolean, default: false },
    description: { type: String },
    error: { type: Boolean },
    errorMsg: { type: String },
    valueHeight: { type: String, default: "" },
    icon: { type: String, default: "" },
    id: { type: String },
    label: { type: String, default: "" },
    labelAlign: { type: String, default: "left" },
    labelClass: { type: String, default: "" },
    labelCols: { type: [String, Boolean, Number], default: "0" },
    marginLeftValue: { type: [String, Number], default: 0 },
    marginLeftLabel: { type: [String, Number], default: 0 },
    placeholder: { type: String, default: "" },
    plaintext: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    rightAlignValue: { type: Boolean, default: false },
    size: { type: String, default: "" },
    state: { type: [Boolean, null], default: null },
    textClass: { type: String, default: "" },
    textFieldWidth: { type: [String, Number], default: "" },
    type: { type: String, default: "text" },
    value: [String, Number],
    uppercase: { type: Boolean, default: false },
  },
  data() {
    return {
      currentCopyIcon: "fas fa-check",
      confirmCopy: false,
      showCopyCheck: false,
      inputValue: "",
      focused: false,
    };
  },
  mounted() {
  },
  computed: {
    tabIndex() {
      return this.readonly || this.plaintext ? "-1" : "0";
    },
    isDisabled() {
      return this.value == null;
    },
    cssVars() {
      return {
        // Note to make this work, must specify ":style = 'cssVars'" in top div element above.
        "--margin-left-label": this.marginLeftLabel,
        "--input-height": this.valueHeight,
      };
    }
  },
  methods: {
    handleFocus() {
      this.focused = true;
    },
    handleBlur() {
      // Allow buttons to be clicked before hiding them
      setTimeout(() => {
        if (!this.$el.contains(document.activeElement)) {
          this.focused = false;
       }
      }, 100);
    },
    updateValue(id, $event) {
      this.$emit("g3Input", { field: id, value: $event });
    },
    touchValue(id, $event) {
      this.$emit("g3Touch", { field: id, value: $event });
    },
    // Copy button methods
    handleCopyClick() {
      if (this.value) {
        navigator.clipboard.writeText(this.value).then(() => {
          this.showCopyCheck = true;
          setTimeout(() => {
            this.showCopyCheck = false;
          }, 2000);
        }).catch(err => {
          console.error('Failed to copy:', err);
          alert(`Unable to copy ${this.value}`);
        });
      }
      this.$emit("onCopyclicked")
      this.$refs.copyTooltip.$emit('close')
    },
    cancelInput() {
      this.inputValue = "";
      this.focused = false;
    },
    submitInput() {
      this.focused = false;
    },

  },
};
</script>

<style lang="css" scoped>

.input-container {
  position: relative;
  display: inline-block;
  overflow: visible;
}

.info-button {
  display: none; /* Hidden by default */
  position: absolute;
  top: 47%;
  right: 7px;
  transform: translateY(-50%);
  background-color: transparent;
  color: rgb(129, 170, 183);
  height: 24px;
  width: 24px;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: box-shadow 0.3s, transform 0.3s;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.input-container:hover .info-button {
  display: flex; /* Show the button on hover */

}

.info-button:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.info-button:active {
  transform: translateY(4px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.action-buttons {
  border: 2px solid red;
  background-color: yellow;
  display: none;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  gap: 8px;
  flex-direction: row;
  z-index: 10;
}

.action-buttons-cancel {
  right: 50px;
}

.action_buttons-ok

.action-buttons button {
  min-width: 40px;
  padding: 4px 8px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  cursor: pointer;
  border-radius: 4px;
  box-sizing: border-box
}

.action-buttons button:hover {
  background-color: #e6e6e6;
}

.input-container.focused .action-buttons {
  display: flex;
  flex-direction: row;

}

.form-group--compact {
  margin-bottom: .4rem;
}

.form-group--compact--readonly {
  margin-bottom: 0px;
}
.input-plaintext {
  margin-top: 2px;
}
.input-readonly {
  border: 0 !important;
}
.input-readonly:focus-within {
  margin-left: 3px !important;
  border: 1px solid #80bdff !important; 
  outline: 4px solid #badafb !important;
  border-radius: 4px;
}

.custom-input {
  height: var(--input-height);
}
.custom-input::placeholder {
  font-style: italic !important;
  color: #888;
}

.button-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.confirm-copy {
  color: red;
}
.check-container {
  display: inline-block;
  position: absolute;
  left: 100%; /* Position the checkmark to the right of the button */
  margin-left: 0; /* Spacing from the button */
  overflow: hidden;
}

.green-check {
  margin: 0;
  padding: 0;
  color: #009933;
  font-size: 0.9rem;
  display: inline-block;
  left: 10px;
}

@keyframes revealCheck {
  to {
    transform: translateX(0); /* Slide into view */
  }
}

@keyframes stayVisible {
  from {
    opacity: 1;
  }
  to {
    opacity: 1; /* No fade; purely for timing */
  }
}

</style>
