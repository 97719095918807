<template>
  <b-container fluid class="item-wrapper">
    <div>
      <b-overlay
        variant="white"
        :show="isLoading && showLoading && useShowLoadingSpinner"
        :opacity="0.0"
        spinner-variant="info"
      >
        <BaseAlert :mode="mode" width="35em" :timeout="7" />
        <b-form
          @submit.prevent="formSave"
          @keydown.enter.exact="formSave"
          @keydown.esc.exact="formCancel"
          enctype="multipart/form-data"
        >
          <!-- Main form card -->
          <b-card
            no-body
            header-tag="header"
            footer-tag="footer"
            class="no-border"
          >
            <!-- Header -->
            <template #header>
              <div class="item-modal-header-content" >
                <!-- Record id -->
                <div>
                  <span class="mr-1 text-muted small">
                    {{ mode === "new" ? "New Item" : `Id:` }}</span
                  >
                  <span v-if="form.id !== -1" class="small">{{ form.id }}</span>
                  <span class="deleted-flag ml-2" v-if="currentItem.deleted">Deleted</span>
                </div>
                <!-- Description  -->
                <h5 class="item-header-description pi-header-container" >
                  <span>{{ headerDescription1() }} </span>
                  <BaseClientBadge
                    :clientId="form.clientId || ''"
                    class="ml-2 mr-2"
                    style="font-weight: 200"
                  />
                  <span> {{ headerDescription2() }} </span>
                </h5>
                <button
                  @click="onCloseClick"
                  class="item-close-btn"
                  aria-label="Close"
                >
                  &times;
                </button>
              </div>
            </template>
            <!-- Card body slot -->
            <b-card no-body class="bg-light pl-2" style="border-top: 2px solid #17A2B8">
              <div style="position: relative">
                <!-- Status text and info -->
                <div id="class-container-id" class="status-container">
                  <span class="mr-1">Status:</span>
                  <BaseInfoButton
                    id="status-def-id"
                    heading="Status Definition"
                    :text="statusDefinition"
                    width="400px"
                    right="0px"
                    class="status-info-icon"
                    @show="hideTooltip('status-def-id')"
                    @hide="showTooltip('status-def-id')"
                  />
                  <b-tooltip
                    v-if="tooltipVisibility['status-def-id']"
                    target="status-def-id"
                    variant="secondary"
                    >Click for <i>Status</i> definition</b-tooltip
                  >
                  <span
                    :class="[
                      'status-text',
                      {
                        'status-text-green': purchasedItemStatus === 'COMPLETE',
                      },
                    ]"
                    >{{ purchasedItemStatus }}</span
                  >
                </div>
                <b-tabs
                  card
                  nav-class="text-capitalize"
                  small
                  active-nav-item-class="font-weight-bold text-uppercase text-info"
                  content-class="mt-0 p-2"
                >
                  <!-- MAIN TAB -->
                  <b-tab lazy title="Main" class="bg-light pb-2">
                    <template>
                      <!-- Main row (entire tab)-->
                      <b-form-row>
                        <!-- Main left column (1 of 2) -->
                        <b-col cols="6" class="p-0 pr-2">
                          <div class="">
                            <div class="d-flex">
                              <!-- Asin -->
                              <BaseFormInputWithCopy 
                                id="productId"
                                label="Asin:"
                                :labelCols="5"
                                :autofocus="!isTenantView"
                                v-model="form.productId"
                                :disabled="!isAuthorized('PurchasedItem', 'update') ||
                                  isTenantView || deleted"
                                @input="$v.form.productId.$touch()"
                                @g3Touch="onAsinChange"
                                :state="getFieldState('productId')"
                                :error="getFieldError('productId')"
                                :errorMsg="`Product id (ASIN) is required`"
                                compact
                                style="width: 70%"
                                size="sm"
                                :marginLeftValue="-3"

                              />
                              <div class="refresh-button-container" v-show="!$v.$anyError">
                                <BaseIconButton
                                  class="ml-2"
                                  style="margin-top: 1px"
                                  id="asin-refresh-id"
                                  ref="asinRefreshButton"
                                  icon="fas fa-sync"
                                  @clicked="onAsinRefreshClicked"
                                />
                                <b-tooltip
                                  target="asin-refresh-id"
                                  variant="secondary"
                                  delay="500"
                                  >Refresh ASIN info</b-tooltip
                                >
                                <span v-if="showCopyCheckAsinRefresh" class="">
                                  <i class="fa fa-check green-check ml-2"></i>
                                </span>
                              </div>
                            </div>
                            <!-- productDescription -->
                            <BaseFormTextarea class="mr-3"
                              id="productDescription"
                              :label-cols="3"
                              placeholder="Product description"
                              autocomplete="off"
                              label="Description:"
                              v-model="form.productDescription"
                              :state="getFieldState('productDescription')"
                              @input="$v.form.productDescription.$touch()"
                              @keydown.enter.stop
                              :disabled="
                                !isAuthorized('PurchasedItem', 'update') ||
                                isTenantView || deleted
                              "
                              :error="$v.form.productDescription.$error"
                              errorMsg="Product description is required"
                              size="sm"
                              :rows="2"
                              compact
                              :marginLeftValue="19"
                            />
                          </div>
                          <hr v-if="!isTenantView" />
                          <div class="mr-3">
                            <!-- Purchased on -->
                            <BaseFormDate
                              id="purchasedOn"
                              :inline="true"
                              :labelCols="3"
                              label="Purchased on:"
                              v-model="form.purchasedOn"
                              :state="getFieldState(form.purchasedOn)"
                              :disabled="isTenantView || deleted"
                              @input="$v.form.purchasedOn.$touch()"
                              size="sm"
                              :valueAsDate=true
                              compact
                              :marginLeftValue="18"
                            />
                          </div>
                          <b-row>
                            <b-col>
                              <!-- Client qty fields - left (purchased, extra)-->
                              <div v-if="!isTenantView">
                                <BaseFormSpinner
                                  v-for="field in qtyFieldsClientLeft"
                                  :key="field.id"
                                  :id="field.id"
                                  :name="field.id"
                                  :label="field.label"
                                  labelWidth="122px"
                                  v-model="field.value"
                                  :min="0"
                                  :max="field.maxValue || 999"
                                  marginLeftValue="3px"
                                  :step="1"
                                  size="sm"
                                  @input="field.setValue($event)"
                                  :labelCols="mode === 'new' ? 5 : 5"
                                  :readonly="
                                    !isAuthorized('PurchasedItem', 'update') || deleted
                                  "
                                  compact
                                />
                              </div>
                            </b-col>
                            <b-col class="p-0">
                              <!-- Client qty fields - right (Canceled, replacement )-->
                              <div v-if="!isTenantView" class="m-0 p-0">
                                <BaseFormSpinner
                                  v-for="field in qtyFieldsClientRight"
                                  :key="field.id"
                                  alignRight
                                  :id="field.id"
                                  :label="field.label"
                                  labelAlign="right"
                                  labelWidth="80px"
                                  :max="field.maxValue || 999"
                                  :min="0"
                                  :name="field.id"
                                  marginRight="13px"
                                  :readonly="
                                    !isAuthorized('PurchasedItem', 'update') || deleted
                                  "
                                  size="sm"
                                  :step="1"
                                  v-model="field.value"
                                  @input="field.setValue($event)"
                                  compact
                                />
                              </div>
                            </b-col>
                          </b-row>
                          <!-- Expected qty row -->
                          <b-row>
                            <b-col>
                              <div class="expected-qty-container">
                                <!-- Expected qty -->
                                <BaseFormSpinner
                                  @input="
                                    handleFieldInput('expectedQty', $event)
                                  "
                                  class="mb-2"
                                  id="expectedQty"
                                  label="Expected qty:"
                                  labelFontColor="#444"
                                  labelFontSize="1.0rem"
                                  labelFontWeight="500"
                                  labelWidth="130px"
                                  marginRight="15px"
                                  readonly
                                  readonlyMarginTop="12px"
                                  readonlyValueFontColor="#444"
                                  readonlyValueFontSize="1.3rem"
                                  readonlyValueFontWeight="600"
                                  :readonlyValueRightAlign="false"
                                  readonlyValueWidth="35px"
                                  :value="expectedQty"
                                />
                                <BaseInfoButton
                                  class="expected-qty-icon"
                                  heading="Expected Quantity"
                                  id="expected-qty-id"
                                  :text="expectedQtyText"
                                  width="400px"
                                  @show="hideTooltip('expected-qty-id')"
                                  @hide="showTooltip('expected-qty-id')"
                                />
                                <b-tooltip
                                  v-if="tooltipVisibility['expected-qty-id']"
                                  target="expected-qty-id"
                                  variant="secondary"
                                  >Click for
                                  <i>Expected</i> definition</b-tooltip
                                >
                                <div
                                  class="expected-summary-container"
                                  v-if="isTenantView"
                                >
                                  <div class="expected-summary">
                                    {{ expectedSummary }}
                                  </div>
                                  <BaseInfoButton
                                    v-if="isTenantView"
                                    buttonType="down"
                                    id="expected-summary-id"
                                    heading="Expected Quantity Detail"
                                    :tableItems="expectedSummaryData"
                                    :tableFields="expectedSummaryFields"
                                    width="300px"
                                    left="0px"
                                    bottom="20px"
                                    class="expected-summary-icon"
                                    @show="hideTooltip('expected-summary-id')"
                                    @hide="showTooltip('expected-summary-id')"
                                  />
                                  <b-tooltip
                                    v-if="
                                      tooltipVisibility[
                                        'expected-summary-id'
                                      ] && isTenantView
                                    "
                                    target="expected-summary-id"
                                    variant="secondary"
                                    >Click for
                                    <i>Expected</i> details</b-tooltip
                                  >
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <!-- Receive progress -->
                              <b-progress class="progress-class">
                                <b-progress-bar
                                  label="Receive progress"
                                  :value="receiveProgress"
                                  :max="expectedQty"
                                  variant="info"
                                  show-progress
                                ></b-progress-bar>
                              </b-progress>
                              <!-- Received NOW qty -->
                              <div
                                class="receive-container mb-2"
                                v-if="isTenantView"
                              >
                                <BaseFormSpinner
                                  id="receivedQty"
                                  name="receivedQty"
                                  label="Received now:"
                                  :autofocus="isTenantView"
                                  labelWidth="120px"
                                  v-model="form.receivedQty"
                                  :max="maxReceivedNow"
                                  :disabled="isReceiveAllButtonDisabled"
                                  :readonly="deleted"
                                  marginRight="15px"
                                  marginLeftValue="10px"
                                  @input="setReceivedQtyField"
                                  class="mb-0 mr-2"
                                />
                                <b-button
                                  class="receive-button receive-all-button"
                                  variant="outline-info"
                                  @click="handleReceiveAllClick"
                                  :disabled="isReceiveAllButtonDisabled"
                                  >{{ receiveAllButtonName }}</b-button
                                >
                              </div>
                              <div class="mt-1" v-if="isTenantView">
                                <!-- Received Tracking Number -->
                                <BaseFormInputWithCopy
                                  id="receivedTrackingNumber"
                                  label="Received tracking #: "
                                  :labelCols="4"
                                  :marginLeftValue="-21"
                                  v-model="form.receivedTrackingNumber"
                                  :disabled="!isAuthorized('PurchasedItem', 'update') || deleted || form.receivedQty === 0"
                                  :state="getFieldState(form.receivedTrackingNumber)"
                                  @input="setReceivedTrackingNumberField"
                                  :error="$v.form.receivedTrackingNumber.$error"
                                  :errorMsg="`Error in Received Tracking number`"
                                  compact
                                  size="sm"
                                />
                              </div>
                            </b-col>
                          </b-row>
                          <b-row class="mt-1">
                            <b-col class="">
                              <!-- Processor Left Area -->
                              <div class="receive-activity-container mb-2">
                                <!-- Total Received qty -->
                                <BaseFormSpinner
                                  id="totalReceivedQty"
                                  name="totalReceivedQty"
                                  label="Total received:"
                                  labelWidth="130px"
                                  readonly
                                  readonlyMarginBottom="0"
                                  formGroupBottom="0"
                                  :value="totalReceivedQty"
                                  valueWidth="26px"
                                />
                                <!-- Receive Activity Dropdown -->
                                <div class="">
                                  <div>
                                    <BaseInfoButton
                                      buttonType="down"
                                      id="receive-activity-id"
                                      heading="Receive Activity"
                                      :tableItems="receiveActivity"
                                      :tableFields="receiveActivityFields"
                                      width="650px"
                                      left="0px"
                                      bottom="20px"
                                      class="receive-activity-icon"
                                      @show="hideTooltip('receive-activity-id')"
                                      @hide="showTooltip('receive-activity-id')"
                                    />
                                    <b-tooltip
                                      v-if="
                                        tooltipVisibility['receive-activity-id']
                                      "
                                      target="receive-activity-id"
                                      variant="secondary"
                                      >Click for
                                      <i>Received Activity</i></b-tooltip
                                    >
                                  </div>
                                  <div>
                                    <b-button
                                      v-if="isTenantView"
                                      class="receive-button receive-adj-button"
                                      :variant="
                                        totalAdjustmentsQty === 0
                                          ? 'outline-info'
                                          : 'info'
                                      "
                                      @click="showReceiveAdjModal"
                                      >Receive/Adjust</b-button
                                    >
                                    <div
                                      class="total-adj-container"
                                      v-if="totalAdjustmentsQty !== 0"
                                    >
                                      <span class="total-adj-label">Adj:</span>
                                      <span>{{ totalAdjustmentsQty }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex">
                                <!-- Total issue qty -->
                                <BaseFormSpinner
                                  id="totalIssueQty"
                                  label="Total issue qty:"
                                  labelWidth="130px"
                                  readonly
                                  :value="calculatedTotalIssueQty"
                                  :readonlyValueRightAlign="false"
                                  readonlyValueWidth="35px"
                                />
                                <span v-show="calculatedTotalIssueReceiveQty>0"
                                  class="issue-receive-qty-exists"
                                  id="issue-receivable-qty"><i>Receivable: {{calculatedTotalIssueReceiveQty}}</i>
                                </span>
                                <b-tooltip
                                  target="issue-receivable-qty"
                                  variant="secondary"
                                  >Issue Action 
                                  is <i>Receive Item</i></b-tooltip
                                >
                              </div>
                              <div class="pending-qty-container">
                                <!-- Pending qty -->
                                <BaseFormSpinner
                                  @input="
                                    handleFieldInput('pendingQty', $event)
                                  "
                                  id="pendingQty"
                                  label="Pending qty:"
                                  labelFontColor="#444"
                                  labelFontSize="1.0rem"
                                  labelFontWeight="500"
                                  labelWidth="122px"
                                  marginLeftValue="8px"
                                  marginRight="15px"
                                  readonly
                                  readonlyMarginTop="10px"
                                  readonlyValueFontColor="#444"
                                  readonlyValueFontSize="1.3rem"
                                  readonlyValueFontWeight="600"
                                  :readonlyValueRightAlign="false"
                                  readonlyValueWidth="35px"
                                  :value="pendingQty"
                                />
                                <BaseInfoButton
                                  id="pending-qty-id"
                                  heading="Pending Quantity"
                                  :text="pendingQtyDefinition"
                                  width="400px"
                                  left="0px"
                                  bottom="20px"
                                  class="pending-qty-icon"
                                  @show="hideTooltip('pending-qty-id')"
                                  @hide="showTooltip('pending-qty-id')"
                                />
                                <b-tooltip
                                  v-if="tooltipVisibility['pending-qty-id']"
                                  target="pending-qty-id"
                                  variant="secondary"
                                  >Click for
                                  <i>Pending</i> definition</b-tooltip
                                >
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col>
                          <!-- Image -->
                          <b-card no-body class="mb-1" v-if="itemImage()">
                            <b-card-body>
                              <b-container class="image-container">
                                <b-img
                                  :src="itemImage()"
                                  fluid
                                  alt="Item image"
                                  class="mx-auto my-auto"
                                  style="max-height: 175px;"
                                ></b-img>
                              </b-container>
                            </b-card-body>
                          </b-card>
                          <!-- Asin clickable link -->
                          <div class="d-flex">
                            <div class="pb-3">
                              <span
                                class="text-muted small"
                                v-if="form.productId"
                              >
                                <b-link
                                  id="amazon-link"
                                  target="_blank"
                                  :href="`https://www.amazon.com/dp/${form.productId}/`"
                                  rel="noopener noreferrer"
                                >
                                  {{ `${form.productId} ` }}
                                </b-link>
                                <b-tooltip
                                  target="amazon-link"
                                  variant="secondary"
                                  >Click to see Amazon listing</b-tooltip
                                >
                              </span>
                            </div>
                            <!-- Asin Copy button -->
                            <div class="ml-1" v-if="form.productId">
                              <div class="copy-button-container">
                                <BaseIconButton
                                  id="asin-copy-id"
                                  icon="fas fa-copy"
                                  @clicked="onCopyClicked('productId')"
                                />
                                <b-tooltip
                                  target="asin-copy-id"
                                  variant="secondary"
                                  delay="1500"
                                  >Copy to clipboard</b-tooltip
                                >
                                <span v-if="showCopyCheckproductId" class="check-container">
                                  <i class="fa fa-check green-check"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- Issues List -->
                          <div class="issues-list" style="height: 225px">
                            <BaseIssueList
                              :issueItems="relatedIssues"
                              :disabled="fromRoute === 'IssueItem' || isFormDirty"
                              @issue:click="onIssueItemClick"
                              @issue:unlink="onUnlinkIssueClick"
                              @issue:delete="onDeleteIssueClick"
                            />
                          </div>
                        </b-col>
                      </b-form-row>
                    </template>
                  </b-tab>
                  <!-- PRODUCT INFO -->
                  <b-tab lazy title="Product Info	" class="bg-light">
                    <template>
                      <b-form-row>
                        <b-col cols="6" class="mr-2 pr-3">
                          <!-- Origin - Select -->
                          <BaseFormSelect
                            id="originId"
                            name="originId"
                            label="Origin:"
                            :labelCols="mode === 'new' ? 4 :4"
                            :namespace="namespace"
                            fieldName="origin"
                            optionsField="options"
                            text-field="originName"
                            value-field="originId"
                            v-model="form.originId"
                            :state="getFieldState('originId')"
                            @input="$v.form.originId?.$touch()"
                            @g3Change="optionFieldChange"
                            :disabled="!isAuthorized('PurchasedItem', 'update') || deleted"
                            :error="$v.form.originId?.$error"
                            @blur="$v.form.originId.$touch()"
                            compact
                            size="sm"
                          />
                          <!-- Source -->
                          <BaseFormCombo
                            id="source"
                            label="Source: "
                            :namespace="namespace"
                            :labelCols="4"
                            v-model="form.source"
                            :readonly="!isAuthorized('PurchasedItem', 'update') || deleted"
                            :state="getFieldState('source')"
                            @input="$v.form.source.$touch()"
                            :error="$v.form.source.$error"
                            :errorMsg="`Error in source`"
                            compact
                            size="sm"
                          />
                          <!-- Supplier -->
                          <BaseFormCombo
                            id="supplier"
                            label="Supplier: "
                            :namespace="namespace"
                            :labelCols="4"
                            v-model="form.supplier"
                            :readonly="!isAuthorized('PurchasedItem', 'update') || deleted"
                            :state="getFieldState('supplier')"
                            @input="$v.form.supplier.$touch()"
                            :error="$v.form.supplier.$error"
                            :errorMsg="`Error in supplier`"
                            compact
                            size="sm"
                          />
                          <BaseFormInputWithCopy
                            v-for="field in productDetailInputLeft"
                            :key="field.id"
                            :id="field.id"
                            :label="field.label"
                            :labelCols="4"
                            v-model="form[field.id]"
                            :disabled="!isAuthorized('PurchasedItem', 'update') || deleted"
                            :state="getFieldState(field.id)"
                            @input="$v.form[field.id].$touch()"
                            :error="getFieldError(field.id)"
                            :errorMsg="`Error in ${field.label}`"
                            compact
                            size="sm"
                          />
                        </b-col>
                        <b-col class="mr-2 pr-3">
                          <!-- cost -->
                          <BaseCurrencyInput
                            id="cost"
                            label="Cost:"
                            labelAlign="right"
                            :labelCols="3"
                            v-model="form.cost"
                            :state="getFieldState('cost')"
                            :readonly="deleted"
                            placeholder="Item cost"
                            @input="$v.form.cost.$touch()"
                            :error="$v.form.cost.$error"
                            :errorMsg="`Error in cost`"
                            compact
                            size="sm"
                          />
                          <!-- msrp -->
                          <BaseCurrencyInput
                            id="msrp"
                            label="MSRP:"
                            labelAlign="right"
                            :labelCols="3"
                            v-model="form.msrp"
                            :readonly="deleted"
                            :state="getFieldState('msrp')"
                            @input="$v.form.msrp.$touch()"
                            :error="$v.form.msrp.$error"
                            :errorMsg="`Error in MSRP`"
                            placeholder="Item MSRP"
                            compact
                            size="sm"
                          />
                          <!-- Expiry -->
                          <BaseFormDate
                            id="expiry"
                            :inline="true"
                            labelAlign="right"
                            :labelCols="3"
                            label="Expiry:"
                            v-model="form.expiry"
                            :disabled="deleted"
                            :state="getFieldState(form.expiry)"
                            @input="$v.form.expiry.$touch()"
                            size="sm"
                            compact
                            :valueAsDate=true
                          />
                          <!-- UPC with Copy -->
                          <BaseFormInputWithCopy
                            id="upc"
                            label="UPC: "
                            :labelCols="3"
                            labelAlign="right"
                            v-model="form.upc"
                            :disabled="!isAuthorized('PurchasedItem', 'update') || deleted"
                            :state="getFieldState(form.upc)"
                            @input="$v.form.upc.$touch()"
                            :error="$v.form.upc.$error"
                            :errorMsg="`Error in UPC`"
                            compact
                            size="sm"
                          />
                          <!-- Model number -->
                          <BaseFormInputWithCopy
                            id="modelNumber"
                            label="Model #: "
                            :labelCols="3"
                            labelAlign="right"
                            v-model="form.modelNumber"
                            :disabled="!isAuthorized('PurchasedItem', 'update') || deleted"
                            :state="getFieldState(form.modelNumber)"
                            @input="$v.form.modelNumber.$touch()"
                            :error="getFieldError('modelNumber')"
                            :errorMsg="`Error in Model Number`"
                            compact
                            size="sm"
                          />
                          <BaseFormInputWithCopy
                            id="partNumber"
                            label="Part #: "
                            :labelCols="3"
                            labelAlign="right"
                            v-model="form.partNumber"
                            :disabled="!isAuthorized('PurchasedItem', 'update') || deleted"
                            :state="getFieldState(form.partNumber)"
                            @input="$v.form.partNumber.$touch()"
                            :error="getFieldError('partNumber')"
                            :errorMsg="`Error in Part Number`"
                            compact
                            size="sm"
                          />
                        </b-col>
                      </b-form-row>
                      <b-form-row>
                        <b-col class="mr-3">
                          <hr />
                          <!-- Order number -->
                          <BaseFormInputWithCopy
                            id="orderNumber"
                            label="Order numbers: "
                            :labelCols="2"
                            :marginLeftValue="-4"
                            v-model="form.orderNumber"
                            :disabled="!isAuthorized('PurchasedItem', 'update') || deleted"
                            :state="getFieldState(form.orderNumber)"
                            @input="$v.form.orderNumber.$touch()"
                            :error="$v.form.orderNumber.$error"
                            :errorMsg="`Error in Order number`"
                            compact
                            size="sm"
                          />
                          <!-- Tracking Number -->
                          <BaseFormInputWithCopy
                            id="trackingNumber"
                            label="Tracking numbers: "
                            :labelCols="2"
                            :marginLeftValue="-4"
                            v-model="form.trackingNumber"
                            :disabled="!isAuthorized('PurchasedItem', 'update') || deleted"
                            :state="getFieldState(form.trackingNumber)"
                            @input="$v.form.trackingNumber.$touch()"
                            :error="$v.form.trackingNumber.$error"
                            :errorMsg="`Error in Tracking number`"
                            compact
                            size="sm"
                          />
                        </b-col>
                      </b-form-row>
                    </template>
                  </b-tab>
                  <!-- OTHER NOTES -->
                  <b-tab lazy title="Other Notes" class="bg-light">
                    <template>
                      <b-form-row>
                        <b-col cols="6" class="mr-2 pr-3">
                          <!-- Client facility - Select -->
                          <BaseFormSelect
                            id="clientFacilityId"
                            name="clientFacilityId"
                            label="Client facility:"
                            :labelCols="mode === 'new' ? 4 : 4"
                            marginLeftValue="3"
                            :namespace="namespace"
                            fieldName="clientFacilityName"
                            optionsField="itemOptions"
                            text-field="clientFacilityName"
                            value-field="clientFacilityId"
                            v-model="form.clientFacilityId"
                            :state="getFieldState('clientFacilityId')"
                            @input="$v.form.clientFacilityId.$touch()"
                            @g3Change="optionFieldChange"
                            :disabled="!isAuthorized('PurchasedItem', 'update') || deleted"
                            :error="$v.form.clientFacilityId.$error"
                            errorMsg="Client facility is required"
                            @blur="$v.form.clientFacilityId.$touch()"
                            compact
                            size="sm"
                          />
                          <!-- Client line of business - Select -->
                          <BaseFormSelect
                            id="clientLobId"
                            name="clientLobId"
                            label="Client LOB:"
                            :labelCols="mode === 'new' ? 4 : 4"
                            marginLeftValue="3"
                            :namespace="namespace"
                            fieldName="clientLobName"
                            optionsField="itemOptions"
                            text-field="clientLobName"
                            value-field="clientLobId"
                            v-model="form.clientLobId"
                            :state="getFieldState('clientLobId')"
                            @input="$v.form.clientLobId.$touch()"
                            @g3Change="optionFieldChange"
                            :disabled="!isAuthorized('PurchasedItem', 'update') || deleted"
                            :error="$v.form.clientLobId.$error"
                            errorMsg="Client line of business is required"
                            @blur="$v.form.clientLobId.$touch()"
                            compact
                            size="sm"
                          />
                        </b-col>
                        <b-col class="">
                          <!-- Processor facility - Select -->
                          <BaseFormSelect
                            id="processorFacilityid"
                            name="processorFacilityId"
                            label="Processor facility:"
                            labelAlign="right"
                            :labelCols="mode === 'new' ? 4 : 4"
                            :namespace="namespace"
                            fieldName="processorFacilityName"
                            optionsField="itemOptions"
                            text-field="processorFacilityName"
                            value-field="processorFacilityId"
                            v-model="form.processorFacilityId"
                            :state="getFieldState('processorFacilityId')"
                            @input="$v.form.processorFacilityId.$touch()"
                            @g3Change="optionFieldChange"
                            :disabled="!isAuthorized('PurchasedItem', 'update') || deleted"
                            :error="$v.form.processorFacilityId.$error"
                            errorMsg="Processor facility is required"
                            @blur="$v.form.processorFacilityId.$touch()"
                            compact
                            size="sm"
                          />
                          <!-- Processor line of business - Select -->
                          <BaseFormSelect
                            id="processorLobId"
                            name="processorLobId"
                            label="Processor LOB:"
                            :labelCols="mode === 'new' ? 4 : 4"
                            labelAlign="right"
                            :namespace="namespace"
                            fieldName="processorLobName"
                            optionsField="itemOptions"
                            text-field="processorLobName"
                            value-field="processorLobId"
                            v-model="form.processorLobId"
                            :state="getFieldState('processorLobId')"
                            @input="$v.form.processorLobId.$touch()"
                            @g3Change="optionFieldChange"
                            :disabled="!isAuthorized('PurchasedItem', 'update') || deleted"
                            :error="$v.form.processorLobId.$error"
                            errorMsg="Processor line of business is required"
                            @blur="$v.form.processorLobId.$touch()"
                            compact
                            size="sm"
                          />
                        </b-col>
                      </b-form-row>
                      <hr />
                      <b-form-row>
                        <b-col cols="12">
                          <!-- itemNotes -->
                          <BaseFormTextarea
                            id="itemNotes"
                            :label-cols="2"
                            autofocus
                            autocomplete="off"
                            label="Item notes"
                            v-model="form.itemNotes"
                            :state="getFieldState('itemNotes')"
                            @input="$v.form.itemNotes.$touch()"
                            @keydown.enter.stop
                            :disabled="!isAuthorized('PurchasedItem', 'update') || deleted"
                            :error="$v.form.itemNotes.$error"
                            size="sm"
                            :rows="3"
                          />
                          <!-- clientNotes -->
                          <BaseFormTextarea
                            id="clientNotes"
                            :label-cols="2"
                            autocomplete="off"
                            label="Client notes"
                            v-model="form.clientNotes"
                            :state="getFieldState('clientNotes')"
                            @input="$v.form.clientNotes.$touch()"
                            @keydown.enter.stop
                            :disabled="!isAuthorized('PurchasedItem', 'update') || deleted"
                            :error="$v.form.clientNotes.$error"
                            size="sm"
                          />
                          <!-- processorNotes -->
                          <BaseFormTextarea
                            id="processorNotes"
                            :label-cols="2"
                            autocomplete="off"
                            label="Processor notes"
                            v-model="form.processorNotes"
                            :state="getFieldState('processorNotes')"
                            @input="$v.form.processorNotes.$touch()"
                            @keydown.enter.stop
                            :disabled="!isAuthorized('PurchasedItem', 'update') || deleted"
                            :error="$v.form.processorNotes.$error"
                            size="sm"
                          />
                        </b-col>
                      </b-form-row>
                    </template>
                  </b-tab>
                  <!-- DETAIL/HISTORY -->
                  <b-tab lazy title="Detail/History	" class="bg-light">
                    <template>
                      <div class="m-1">
                        <b-form-row>
                          <h4 class="pb-0 pl-1 history-heading">
                            Record Detail
                          </h4>
                        </b-form-row>
                        <b-form-row>
                          <b-col cols="7">
                            <div>
                              <!-- Top-left controls -->
                              <BaseFormInput
                                v-for="item in topLeft"
                                :key="item.id"
                                :id="item.id"
                                :label="item.label"
                                :labelCols="4"
                                plaintext
                                labelClass="text-muted"
                                size="sm"
                                readonly
                                @blur="$v.form[item.id].$touch()"
                                compact
                                :value="item.value"
                              />
                            </div>
                          </b-col>
                          <b-col>
                            <div>
                              <!-- Top-right controls -->
                              <BaseFormInput
                                v-for="item in topRight"
                                :key="item.id"
                                :id="item.id"
                                :label="item.label"
                                :labelCols="5"
                                labelAlign="right"
                                plaintext
                                labelClass="text-muted"
                                size="sm"
                                readonly
                                compact
                                :value="item.value"
                              />
                            </div>
                          </b-col>
                        </b-form-row>
                        <hr />
                        <!-- History -->
                        <b-form-row>
                          <div
                            class="d-flex align-items-center justify-content-between"
                          >
                            <span class="d-flex align-items-center">
                              <h4 class="pb-2 pl-1 mb-0 history-heading">
                                Record History
                              </h4>
                            </span>
                            <span class="ml-4 text-smaller"
                              >(most recent first)</span
                            >
                          </div>
                        </b-form-row>
                        <div v-if="noHistory" class="no-history">
                          No history to display
                        </div>
                        <div v-if="!noHistory">
                          <div class="history-scrollable-list">
                            <b-list-group
                              v-for="(hx, index) in currentItem.logs"
                              :key="index"
                              class="p-0 history-item"
                            >
                              <b-list-group-item class="px-3 py-1">
                                <BaseLogItem :item="hx" userWidth="120px" />
                              </b-list-group-item>
                            </b-list-group>
                          </div>
                        </div>
                      </div>
                    </template>
                  </b-tab>
                </b-tabs>
              </div>
            </b-card>
            <!-- Card footer slot -->
            <template #footer>
              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <b-button
                  v-if="fromRoute !== 'IssueItem' && mode !== 'new'"
                    class="mr-3"
                    variant="secondary"
                    @click="createLinkedIssue"
                    :disabled="deleted || isFormDirty"
                    size="sm"
                    >
                    <i class="fa fa-flag" aria-hidden="true"></i>
                    Create Issue</b-button
                  >
                  <b-button
                    class="mr-3"
                    variant="secondary"
                    @click="onUndelete"
                    v-if="currentItem.deleted"
                    size="sm"
                  >
                    Undelete Issue
                  </b-button>
                </div>
                <div class="d-flex">
                  <b-button
                    class="mr-3"
                    variant="secondary"
                    @click="formCancel()"
                    >{{ isFormDirty ? "Cancel" : "Close" }}</b-button
                  >
                  <b-button
                    type="submit"
                    variant="primary"
                    :disabled="!isFormDirty && mode !== 'new'"
                    v-if="isAuthorized('PurchasedItem', 'update')"
                    >Save</b-button
                  >
                </div>
              </div>
            </template>
          </b-card>
        </b-form>
      </b-overlay>
      <!-- *******************  RECEIVE ADJUST/HX MODAL  ***************************** -->
      <!-- ReceiveAdj modal -->
      <b-modal
        id="ReceiveAdjModal"
        ref="ReceiveAdjModalRef"
        hide-footer
        hide-header
        content-class="shadow"
        no-close-on-esc
        no-close-on-backdrop
        @shown="onReceiveAdjModalOpen"
        @hidden="onReceiveAdjModalClose"
      >
        <ReceiveAdj
          ref="ReceiveAdjRef"
          @cancelModal="cancelReceiveAdjModal"
          @save-receive-item="saveReceiveAdjModal"
          :parentTenantId="form.tenantId"
          :parentClientId="form.clientId"
          :parentId="form.id"
          :expectedQty="expectedQty"
          :totalIssueQty="totalIssueQty"
        />
      </b-modal>
    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapActions, } from "vuex";
// import _ from "lodash"
import { required, decimal } from "vuelidate/lib/validators";
import {
  handleBeforeDestroy,
  handleCreated,
  handleFormCancel,
  handleFormSave,
  handleItemImage,
  handleSyncOptionName,
  handleValidateState,
  handleModalOnOutsideClick,
  handleConfirmChanges2,
  postMsgBoxOk,
  handleCopyClicked,
} from "../../utils/component-utils";
import ReceiveAdj from "./ReceiveAdj.vue";
//import draggableMixin from "../common/draggableMixin.js";

export default {
  components: { ReceiveAdj },
  //  mxins: [draggableMixin],
  name: "PurchasedItem",
  props: {
    namespace: { type: String, default: "PurchasedItem" },
    showLoadingSpinner: { type: Boolean, default: true },

  },
  data() {
    return {
      mode: "new",
      originalAsin: "",
      copyIcon: "fas fa-copy",
      showCopyCheckproductId: false,
      showCopyCheckAsinRefresh: false,
      isTenantView: false,
      isClosing: false,
      // isIssueItemModalOpen: false,
      isReceiveAdjMmodalOpen: false,
      isDataLoaded: false,
      form: {
        activity: "",
        canceledQty: 0,
        clientFacilityId: 0,
        clientFacilityName: "",
        clientId: "",
        clientLobId: 0,
        clientLobName: "",
        clientName: "",
        clientNotes: "",
        cost: 0,
        createdBy: "",
        createdOn: "",
        expiry: "",
        extraQty: 0,
        fnsku: "",
        id: "",
        itemNotes: "",
        modelNumber: "",
        msrp: 0,
        orderNumber: "",
        originId: null,
        partNumber: "",
        pastReceivedQty: 0,
        processorFacilityId: 0,
        processorFacilityName: "",
        processorLobId: 0,
        processorLobName: "",
        processorName: "",
        processorNotes: "",
        productDescription: "",
        productId: "",
        purchasedOn: "",
        purchasedQty: 0,
        receivedQty: 0,
        receivedTrackingNumber: "",
        replacementQty: 0,
        sku: "",
        source: "",
        supplier: "",
        trackingNumber: "",
        tenantId: "",
        ts: "",
        upc: "",
        lastUpdatedBy: "",
      },
      confirmingChanges: false,
      childDirty: false,
      maxReceivedNow: 0,
      sourcPathName: "",
      isReadonly: false,
      infoPopoverShow: false,
      tooltipVisibility: {
        "asin-refresh-id": true,
        "status-def-id": true,
        "expected-qty-id": true,
        "pending-qty-id": true,
        "receive-activity-id": true,
        "expected-summary-id": true,
      },
      totalIssueQty: this.calculatedTotalIssueQty,
      totalAdjustmentsQty: 0,
      receiveButtonState: true,
      useShowLoadingSpinner: this.$props.showLoadingSpinner,
      creatingIssue: false,
      deletingIssue: false,
    };
  },
  validations: {
    form: {
      clientFacilityId: {},
      clientLobId: {},
      clientNotes: {},
      cost: { decimal },
      expiry: {},
      fnsku: {},
      itemNotes: {},
      modelNumber: {},
      msrp: { decimal },
      orderNumber: {},
      originId: {},
      partNumber: {},
      pastReceivedQty: {},
      processorFacilityId: {},
      processorLobId: {},
      processorNotes: {},
      productDescription: { required },
      productId: { required },
      purchasedOn: { required },
      sku: {},
      source: {},
      supplier: {},
      trackingNumber: {},
      upc: {},
      // Quantity fields
      canceledQty: {},
      extraQty: {},
      purchasedQty: {
        required,
        minValue: 1,
      },
      receivedQty: {},
      receivedTrackingNumber: {},
      replacementQty: {},
    },
  },
  async created() {
    try {
      this.isTenantView = this.user.scope === "tenant";
      await handleCreated(this, document);
      this.form.receivedQty = 0;
      this.form.receivedTrackingNumber = "";
      // Reset form validation
      this.$nextTick(() => {
        this.totalIssueQty = this.currentItem?.issueQtySum;
        this.maxReceivedNow = this.pendingQty;
        this.updateTotalAdjustmentsQty();
        this.$v.$reset();
      });
      this.originalAsin = this.form.productId;
      this.isDataLoaded = true;
    } catch (error) {
      console.error("Error during component creation", error);
    }
  },
  mounted() {
    this.$nextTick(() => { });
    
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      // `vm` is the component instance
      if (from.name === 'IssueItem') {
        await vm.updateIssueItem();
      }
    });
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isFormDirty) {
      const reply = await handleFormCancel(this, false);
      if (reply === null) {
        next(false);
      } else {
        next();
      }
    } else {
      next();
    }
  },

  computed: {
    ...mapGetters("Session", [
      "isLoading",
      "showLoading",
      "isAuthorized",
      "user",
      "authToken",
      "fromItemDetail"
    ]),
    // item() {
    //   // Parse the item from query parameters if it exists
    //   return this.$route?.query?.item ? JSON.parse(this.$route?.query?.item) : null;
    // },

    fromRoute() {
      return this.$route?.query?.from;
    },
    relatedIssues() {
      return this.$store.getters[`${this.namespace}/relatedIssues`];
    },
    activeIssueQty() {
      return this.relatedIssues?.reduce((total, issue) => {
        return issue?.issueStatus?.closedOption !== true
          ? total + (issue.issueQty || 0)
          : total;
      }, 0);
    },
    calculatedTotalIssueQty() {
      return this.relatedIssues?.reduce((total, issue) => {
        return total + (issue.issueQty || 0);
      }, 0);
    },

    calculatedTotalIssueReceiveQty() {
      return this.relatedIssues?.reduce((total, issue) => {
        // Check if issueAction.actionName is "Receive item"
        if (issue.issueAction?.actionName === "Receive item") {
          return total + (issue.issueQty || 0);
        }
        return total;
      }, 0) || 0; // Return 0 if relatedIssues is null or undefined
    },

   // Store to component data binding
    currentItem() {
      return this.$store.getters[`${this.namespace}/currentItem`];
    },
    deleted() {
      return this.currentItem.deleted === 1 ? true : false;
    },
    receiveActivity() {
      const records = this.$store.getters[`${this.namespace}/receiveActivity`]
      return records.slice()
        .sort((a, b) => new Date(b.ts) - new Date(a.ts))
        .map((activity) => ({
          ...activity,
          activityType: activity.isAdjustment ? "Adjustment" : "Received",
        }));
    },
    receiveActivityFields() {
      return [
        {
          key: "activityType",
          label: "Activity",
          class: ["col-10-pct"],
        },
        {
          key: "receivedQty",
          label: "Qty",
          class: ["col-center"],
        },
        {
          key: "receivedTrackingNumber",
          label: "Tracking #",
          class: ["col-25-pct"],
          sortable: true,
        },
        {
          key: "dateData",
          label: "Date",
          sortable: true,
          formatter: (value, key, item) => {
            if (item.ts) {
              const dateObj = new Date(item.ts);
              const date = dateObj.toLocaleDateString(); // Formats date part
              const time = dateObj.toLocaleTimeString(); // Formats time part
              return `<div class="small-text col-light-text">${date}</div><div class="small-text col-light-text">${time}</div>`;
            }
            return "";
          },
        },
        {
          key: "byData",
          label: "By",
          sortable: true,
          formatter: (value, key, item) => {
            if (item.lastUpdatedBy) {
              return `<div class="small-text col-light-text">${item.lastUpdatedBy}`;
            }
            return "";
          },
        },
      ];
    },
    expectedSummaryData() {
      const data = [];
      data.push({
        action: "Purchased",
        qty: this.form.purchasedQty,
        when: this.form.purchasedOn,
      });
      if (this.form.canceledQty > 0) {
        data.push({
          action: "Canceled",
          qty: this.form.canceledQty,
          when: null,
        });
      }
      if (this.form.extraQty > 0) {
        data.push({
          action: "Extra",
          qty: this.form.extraQty,
          when: null,
        });
      }
      if (this.form.replacementQty > 0) {
        data.push({
          action: "Replacement",
          qty: this.form.replacementQty,
          when: null,
        });
      }
      return data;
    },
    expectedSummaryFields() {
      return [
        {
          key: "action",
          label: "Client Action",
          thStyle: { width: "150px" },
          tdStyle: { width: "150px" },
        },
        {
          key: "qty",
          label: "Qty",
          thStyle: { width: "80px" },
          tdStyle: { width: "80px" },
        },
        {
          key: "when",
          label: "Date",
          sortable: true,
          formatter: (value, key, item) => {
            return item.when && new Date(item.when).toLocaleDateString();
          },
          thStyle: { width: "100px" },
          tdStyle: { width: "100px" },
        },
      ];
    },
    isFormDirty() {
      return this.childDirty || this.$v.$anyDirty;
    },
    isReceiveAllButtonDisabled() {
      return this.pendingQty === 0 && this.receiveButtonState;
    },

    sourceOptions() {
      return ["one", "two", "three"];
    },
    //* **************************************
    //           Entity-specific Computed
    //* **************************************
    receiveProgress() {
      return this.expectedQty - this.pendingQty;
    },
    qtyFieldsClientLeft() {
      return [
        {
          id: "purchasedQty",
          label: "Purchased qty:",
          value: Number(this.form.purchasedQty) || 0,
          setValue: (val) => {
            this.form.purchasedQty = Number(val) || 0;
            this.$v.form.purchasedQty.$touch();
          },
        },
        {
          id: "extraQty",
          label: "Extra qty:",
          value: Number(this.form.extraQty) || 0,
          setValue: (val) => {
            this.form.extraQty = Number(val) || 0;
            this.$v.form.extraQty.$touch();
          },
        },
      ];
    },
    qtyFieldsClientRight() {
      return [
        {
          id: "canceledQty",
          label: "Canceled:",
          value: Number(this.form.canceledQty) || 0,
          setValue: (val) => {
            this.form.canceledQty = Number(val) || 0;
            this.$v.form.canceledQty.$touch();
          },
          maxValue:
            Number(this.form.purchasedQty || 0) +
            Number(this.form.extraQty || 0) +
            Number(this.replacementQty || 0) -
            Number(this.totalReceivedQty || 0),
        },
        {
          id: "replacementQty",
          label: "Replace:",
          value: Number(this.form.replacementQty) || 0,
          setValue: (val) => {
            this.form.replacementQty = Number(val) || 0;
            this.$v.form.replacementQty.$touch();
          },
          maxValue:
            Number(this.form.purchasedQty || 0) +
            Number(this.form.extraQty || 0),
        },
      ];
    },
    productDetailInputLeft() {
      return [
        { id: "sku", label: "SKU:", value: "sku" },
        { id: "fnsku", label: "FnSku:", value: "fnSku" },
      ];
    },
    // Detail/history
    topLeft() {
      return [
        { id: "id", label: "Record id:", value: this.form.id },
        {
          id: "processorName",
          label: "Processor name:",
          value: this.form.processorName,
        },
        {
          id: "clientName",
          label: "Client name:",
          value: this.form.clientName,
        },
        { id: "tenantId", label: "Tenant id:", value: this.form.tenantId },
        { id: "clientId", label: "Client id:", value: this.form.clientId },
      ];
    },
    // Detail/history
    topRight() {
      return [
        {
          id: "createdOn",
          label: "Created on:",
          value: new Date(this.form.createdOn).toLocaleString(),
        },
        { id: "createdBy", label: "Created by:", value: this.form.createdBy },
        {
          id: "lastEditedOn",
          label: "Last updated on:",
          value: new Date(this.form.ts).toLocaleString(),
        },
        {
          id: "lastUpdatedBy",
          label: "Last updated by:",
          value: this.form.lastUpdatedBy,
        },
      ];
    },
    noHistory() {
      if (!this.isDataLoaded || !this.currentItem) return true;
      if (
        this.currentItem &&
        this.currentItem.logs &&
        this.currentItem.logs.length > 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    totalReceivedQty() {
      return Number(
        (Number(this.form.pastReceivedQty) || 0) +
          (Number(this.form.receivedQty) || 0)
      );
    },
    // ***********************************************
    //         Info Popovers
    // ***********************************************
    purchasedItemStatus() {
      return this.pendingQty !== 0 || this.activeIssueQty > 0 || this.form.id === -1
        ? "PENDING"
        : "COMPLETE";
    },
    statusDefinition() {
      return `<p>Purchased item <i>Status</i> is automatically set to <span style="font-weight: 500;">PENDING</span> until all items are received and all issues are closed, at which time, it is changed to <span style="font-weight: 500;">COMPLETE</span>:</p><p><span style="font-weight: 500;">Pending</span>: <i>Pending</i> qty > 0  OR  <i>Active Issues</i> qty > 0<br>
      <span style="font-weight: 500;">Complete</span>: <i>Pending</i> qty = 0  AND  <i>Active Issues</i> qty = 0 </p>`;
    },
    expectedQty() {
      return Number(
        (Number(this.form.purchasedQty) || 0) -
          (Number(this.form.canceledQty) || 0) +
          (Number(this.form.extraQty) || 0) +
          (Number(this.form.replacementQty) || 0)
      );
    },
    expectedQtyText() {
      return "<p><i>Expected quantity</i> is the total number of units expected to arrive at the processor's facility as a result of this order. It is calculated as:</p><p><i>Purchased</i> - <i>Canceled</i> + <i>Extra</i> + <i>Replacement</i>.";
    },
    expectedSummary() {
      const { purchasedQty, extraQty, replacementQty, canceledQty } = this.form;
      const summary = `Purchased: ${purchasedQty}`;
      const adjustments =
        (Number(extraQty) || 0) +
        (Number(replacementQty) || 0) -
        (Number(canceledQty) || 0);
      const adjustmentString =
        adjustments !== 0
          ? `${adjustments > 0 ? "+" : "-"}${Math.abs(adjustments)}`
          : "";
      const adjustmentPhrase = adjustmentString
        ? `(Other: ${adjustmentString})`
        : "";
      return `${summary} ${adjustmentPhrase}`.trim();
    },
    pendingQty() {
      return Number(
        (Number(this.expectedQty) || 0) -
          (Number(this.totalReceivedQty) || 0) -
          (Number(this.calculatedTotalIssueQty) || 0) +
          (Number(this.calculatedTotalIssueReceiveQty) || 0)
      );
    },
    pendingQtyDefinition() {
      return `<p><i>Pending</i> quantity is what's still expected to arrive at the processor. It is calculated as:<br><br><i>Expected qty</i> - <i>Total received qty</i> - <i>Total Issue qty</i> + Issue quantity marked with <i>Receive Item</i> Action`;
    },
    receiveAllButtonName() {
      return this.receiveButtonState ? "Receive All" : "Reset";
    },
  },
  watch: {
    relatedIssues: {
      handler() {
        this.totalIssueQty = this.calculatedTotalIssueQty;
        this.maxReceivedNow = this.pendingQty;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("Notification", ["toastMsgAdd", "toastAdd"]),

    onAsinChange(event){
      this.form.productId = event.value
      this.$refs.asinRefreshButton.triggerAnimation()
    },

    async onAsinRefreshClicked() {
      const oldProductId = this.currentItem.productId;
      this.form.productId = this.form.productId.trim();
      const newProductId = this.form.productId;
      const data = { key: "productId", value: this.form.productId.trim() };
      this.$store.commit(`${this.namespace}/CURRENT_ITEM_FIELD_SET`, data);
      const result = await this.$store.dispatch(`${this.namespace}/refreshAsinData`);
      if (result) {
        // Found asin: Change related info
        this.form.upc = this.currentItem.upc;
        this.form.msrp = this.currentItem.msrp;
        this.form.productDescription = this.currentItem.productDescription;
        this.form.modelNumber = this.currentItem.modelNumber;
        this.form.partNumber = this.currentItem.partNumber;
        let msg;
        // Toast confirmation
        if (newProductId !== oldProductId) {
          msg = `Changed ASIN '${oldProductId}' to ${newProductId}. Save record to finish.`;
          await this.toastAdd({ message: msg, title: "Success", variant: "info"});
        } else {
          msg = `Refreshed ASIN '${newProductId}'. Save record to finish.`;
          await this.toastAdd({ message: msg, title: "Success", variant: "secondary"});
        }
        // postMsgBoxOk(this, "Refresh ASIN", msg, "success")
        this.originalAsin = newProductId;
        this.showCopyCheckAsinRefresh = true;
        setTimeout(() => {
          this.showCopyCheckAsinRefresh = false;
        }, 1000);

      } else {
        // Not found; clear 
        this.form.imageUrl = ""
        this.form.upc = ""
        this.form.msrp = 0,
        this.productDescription = ""
        this.form.modelNumber = ""
        this.form.partNumber = ""
        postMsgBoxOk(this, "Refresh ASIN", `ASIN '${newProductId}' not found.`, "warning")
      }
      this.$v.form.$touch();
    },

    onCopyClicked(field){
      handleCopyClicked(this, field);
    },

    async onUndelete() {
      this.$store.dispatch(`${this.namespace}/baseUndelete`);
      this.$v.form.$touch();
    },

    updateTotalAdjustmentsQty() {
      const sumReceivedQty = this.receiveActivity
        .filter((activity) => activity.isAdjustment)
        .reduce((sum, activity) => sum + (activity.receivedQty || 0), 0);
      this.totalAdjustmentsQty = sumReceivedQty;
    },
    handleReceiveAllClick() {
      if (!this.receiveButtonState) {
        // Reset to 0
        this.setReceivedQtyField(0);
      } else {
        // Receive all
        if (this.pendingQty <= 0) return;
        const newValue =
          (Number(this.expectedQty) || 0) -
          (Number(this.form.pastReceivedQty) || 0) -
          (Number(this.totalIssueQty) || 0) +
          (Number(this.calculatedTotalIssueReceiveQty) || 0);
        this.setReceivedQtyField(newValue);
      }
      this.receiveButtonState = !this.receiveButtonState;
    },
    headerDescription1() {
      let description = "Purchased Item";
      if (this.form.asin || this.form.productId) {
        const onText = ` on ${this.form.asin || this.form.productId}`;
        description += onText;
      }
      return description;
    },
    headerDescription2() {
      let description = "";
      if (this.form.clientName) {
        const forText = `for ${this.form.clientName.length > 50
            ? `${this.form.clientName.substring(0, 50)}...`
            : this.form.clientName
        }`;
        description += forText;
      }
      return description;
    },

    setReceivedQtyField(val) {
      this.form.receivedQty = Number(val);
      this.$v.form.receivedQty.$touch();
    },
    setReceivedTrackingNumberField(val) {
      this.form.receivedTrackingNumber = val;
      this.$v.form.receivedTrackingNumber.$touch();
    },
    handleFieldInput(fieldName, value) {
      this.form[fieldName] = value;
      if (this.$v.form[fieldName]) {
        this.$v.form[fieldName].$touch();
      }
    },
    hideTooltip(id) {
      this.tooltipVisibility[id] = false;
    },
    showTooltip(id) {
      this.tooltipVisibility[id] = true;
    },
    // *********************************************
    //          Entity-specific Methods
    // *********************************************
    formatCurrency(field) {
      const numericValue = parseFloat(
        this.form[field].replace(/[^0-9.-]+/g, "")
      );
      if (!isNaN(numericValue)) {
        this.form[field] = numericValue.toFixed(2);
      } else {
        this.form[field] = "";
      }
    },
    headerDescription() {
      return `Purchased Item: ${this.form.productId}`;
    },
    optionFieldChange({ field, value }) {
      // Option dropdowns: Sync id and name
      if (field === "processorFacility_Id") {
        this.optionFieldSync(value, field, "processorFacilityName");
      } else if (field === "clientFacilityId") {
        this.optionFieldSync(value, field, "clientFacilityName");
      } else if (field === "tenantLobId") {
        this.optionFieldSync(value, field, "processorLobName");
      } else if (field === "clientLobId") {
        this.optionFieldSync(value, field, "clientLobName");
      }
    },
    optionFieldSync(value, valueField, textField) {
      handleSyncOptionName({
        thisObj: this,
        value,
        valueField,
        textField,
        optionsField: "itemOptions",
      });
    },
    // *********************************************
    //          Standard Item Methods
    // *********************************************
    beforeDestroy() {
      handleBeforeDestroy(this, document);
    },
    getFieldError(fieldId) {
      return this.$v.form[fieldId].$error;
    },
    getFieldState(data) {
      return handleValidateState(this, data);
    },
    itemImage() {
      return handleItemImage(this);
    },
    onInput(fieldId) {
      this.$v.form[fieldId].$touch();
    },

    // * ******************************************************** */
    // *           SHOW/HIDE ISSUE DETAIL 
    // * ******************************************************** */
    async onIssueItemClick(item) {
      const clonedItem = {...item}
      const issueItem = { ...this.populateIssueItem(clonedItem) };
      this.$store.dispatch("Issue2/currentItemInitialize", issueItem);
      this.routeToIssue(issueItem.id)
    },

    populateIssueItem(item) {
      item.asin = this.currentItem.productId;
      item.fnsku = this.currentItem.fnsku;
      item.trackingNumber = this.currentItem.trackingNumber;
      item.orderNumber = this.currentItem.orderNumber;
      item.productDescription = this.currentItem.productDescription;
      item.productId = this.currentItem.productId;
      item.purchasedItemRecordId = this.currentItem.id;
      item.sku = this.currentItem.sku;
      item.upc = this.currentItem.upc;
      item.purchasedQty = this.currentItem.purchasedQty;
      item.purchasedOn = this.currentItem.puchasedOn;
      item.pastReceivedQty = this.currentItem.pastReceivedQty;
      item.supplier = this.currentItem.supplier;
      item.canceledQty = this.currentItem.canceledQty;
      item.extraQty = this.currentItem.extraQty;
      item.replacementQty = this.currentItem.replacementQty;
      item.imageUrl = this.currentItem.imageUrl;
      return item;
    },

    async updateIssueItem() {
      this.$nextTick( async () => {
        // Refresh relatedIssues
        const payload = {
          purchasedItemId: this.form.id,
          clientId: this.form.clientId,
          tenantId: this.form.tenantId,
        }
        await this.$store.dispatch(`${this.namespace}/fetchRelatedIssues`, payload);
        this.updateIssueStatusInListItem();
        // Log issue create
        if (this.creatingIssue & this.relatedIssues?.length > 0) {
          this.creatingIssue = false;
          const maxIssue = this.relatedIssues.reduce((max, obj) =>
            obj.id > max.id ? obj : max
          );
          const logRecord = {
            id: this.form.id,
            clientId: this.form.clientId,
            tenantId: this.form.tenantId,
            activity: "New issue created and linked",
            linkedIssueId: maxIssue.id,
            issueType: maxIssue.issueType.typeName
          };
          await this.$store.dispatch(`${this.namespace}/logActivity`, logRecord)
        }
      });
    },

    updateIssueStatusInListItem() {
      let data = { key: "hasIssueCount", value: this.relatedIssues?.length > 0 };
      this.$store.commit(`${this.namespace}/CURRENT_ITEM_FIELD_SET`, data);
      data = { key: "hasActiveIssue", value: this.activeIssueQty > 0 };
      this.$store.commit(`${this.namespace}/CURRENT_ITEM_FIELD_SET`, data);
      data = { key: "issueQtySum", value: this.calculatedTotalIssueQty || 0 };
      this.$store.commit(`${this.namespace}/CURRENT_ITEM_FIELD_SET`, data);
      data = { key: "issueQtySumActive", value: this.activeIssueQty || 0};
      this.$store.commit(`${this.namespace}/CURRENT_ITEM_FIELD_SET`, data);
      data = { key: "issueQtyReceivable", value: this.calculatedTotalIssueReceiveQty || 0};
      this.$store.commit(`${this.namespace}/CURRENT_ITEM_FIELD_SET`, data);
      data = { key: "issueCount", value: this.relatedIssues?.length || 0 };
      this.$store.commit(`${this.namespace}/CURRENT_ITEM_FIELD_SET`, data);
      this.totalIssueQty = this.calculatedTotalIssueQty;
      this.maxReceivedNow = this.pendingQty;
    },

    async createLinkedIssue() {
      this.creatingIssue = true;
      const newIssue = this.populateIssueItem({});
      const item = {
        id: -1,
        parentTenantId: this.currentItem.tenantId,
        parentClientId: this.currentItem.clientId,
        clientId: this.currentItem.clientId,
        tenantId: this.currentItem.tenantId,
        clientName: this.currentItem.clientName,
        processorName: this.currentItem.processorName,
        clientFacilityId: this.currentItem.clientFacilityId,
        clientLobId: this.currentItem.clientLobId,
        processorFacilityId: this.currentItem.processorFacilityId,
        processorLobId: this.currentItem.processorLobId,
        createdOn: new Date(),
        lastUpdatedOn: new Date(),
        imageUrl: newIssue.imageUrl,
      };
      const newItem = {...item, ...newIssue}
      // Create a new currentItem for linked issue
      this.$store.dispatch("Issue2/currentItemInitialize", newItem);
      this.routeToIssue(newItem.id)
    },

    routeToIssue(id) {
      this.$store.commit("Session/SET_FROM_ITEM_DETAIL", true);
      const originatingRoute = this.$route.name;
      this.$router.push({
        name: "IssueItem",
        params: {id},
        query: {
          callingClientId: this.currentItem.clientId,
          callingClientName: this.currentItem.clientName,
          callingTenantId: this.currentItem.tenantId,
          from: originatingRoute,
        },
      });
    },

    async onUnlinkIssueClick(item) {
      const message = `Are you sure you want to unlink this issue (id: ${item.id}, control number: ${item.controlNumber}) from Purchased Item record id: ${this.form.id}?`;
      const answer = await handleConfirmChanges2({ thisObj: this, message });
      if (!answer) return;
      // Save issue to unlink
      await this.$store.dispatch("Issue2/itemSaveUnlinked", item);
      // Refresh related issues
      await this.$store.dispatch(`${this.namespace}/fetchRelatedIssues`, {
        purchasedItemId: this.form.id,
        clientId: this.form.clientId,
        tenantId: this.form.tenantId,
      });
      this.updateIssueStatusInListItem(false);
      // Log unlinked related issue
      const logRecord = {
        id: this.form.id,
        clientId: this.form.clientId,
        tenantId: this.form.tenantId,
        activity: "Issue unlinked",
        linkedIssueId: item.id,
        issueType: item.issueType.typeName
      };
      await this.$store.dispatch(`${this.namespace}/logActivity`, logRecord)
    },

    async onDeleteIssueClick(item) {
      this.deletingIssue = true;
      const message = `Are you sure you want to delete this issue (id: ${item.id}, control number: ${item.controlNumber})?`;
      const answer = await handleConfirmChanges2({ thisObj: this, message, okVariant: 'danger' });
      if (!answer) return;
      // Delete issue
      await this.$store.dispatch("Issue2/itemsDelete", [item]);
      this.updateIssueStatusInListItem(false);
      // Refresh related issues
      await this.$store.dispatch(`${this.namespace}/fetchRelatedIssues`, {
        purchasedItemId: this.form.id,
        clientId: this.form.clientId,
        tenantId: this.form.tenantId,
      });
      // Log delete related issue
      const logRecord = {
        id: this.form.id,
        clientId: this.form.clientId,
        tenantId: this.form.tenantId,
        activity: "Issue deleted",
        linkedIssueId: item.id,
        issueType: item.issueType.typeName
      };
      await this.$store.dispatch(`${this.namespace}/logActivity`, logRecord)
    },

    // *********************************************
    //          Show/hide Receive Adj/Hx modal
    // *********************************************
    async showReceiveAdjModal() {
      this.$bvModal.show("ReceiveAdjModal");
    },
    cancelReceiveAdjModal() {
      this.$bvModal.hide("ReceiveAdjModal");
    },
    async saveReceiveAdjModal() {
      this.form.pastReceivedQty = this.currentItem.pastReceivedQty;
      this.maxReceivedNow = this.pendingQty;
      this.updateTotalAdjustmentsQty();
      this.$v.form.pastReceivedQty.$touch();
    },
    onReceiveAdjModalOpen() {
      this.isReceivedAdjMmodalOpen = true;
      document.addEventListener("click", this.onReceiveAdjOutsideClick);
    },
    onReceiveAdjModalClose() {
      this.isReceivedAdjMmodalOpen = false;
      document.removeEventListener("click", this.onReceiveAdjOutsideClick);
    },
    async onReceiveAdjOutsideClick(event) {
      const receiveAdjModalEl = this.$refs.ReceiveAdjModalRef?.$el;
      // Check if click is outside ReceiveAdj modal
      const isClickOutsideReceiveAdjItem =
        receiveAdjModalEl && !receiveAdjModalEl?.contains(event.target);
      if (isClickOutsideReceiveAdjItem) {
        await handleModalOnOutsideClick(
          event,
          this,
          "ReceiveAdjModalRef",
          "ReceiveAdjRef"
        );
      }
    },

    // *********************************************
    //          Item validate/close/cancel Methods
    // *********************************************
    onCloseClick(event) {
      event.stopPropagation();
      event.preventDefault();
      this.formCancel();
    },
    async formCancel() {
      this.$store.commit("Session/SET_FROM_ITEM_DETAIL", true);
      this.updateTransientData();      
      await handleFormCancel(this);
    },
    async formSave(route = true) {
      if (this.form.productId !== this.originalAsin) {
        // Asin is changed and not refreshed. Must refresh before saving
        postMsgBoxOk(this, "ASIN Changed", "Must refresh ASIN before saving.", "warning")
        return;
      }
      this.$store.commit("Session/SET_FROM_ITEM_DETAIL", true);
      if (this.id === -1) {
        // Saving a newly created record
        // this.$store.commit("Session/SET_CREATING_NEW_ITEM", true);
      }
      this.updateTransientData();
      await handleFormSave(this, route);
      this.$emit("savePiItem", this.form);
      this.$emit("itemSaved");
    },

    async updateTransientData() {
      // Update transient values so they'll appear in the list
      this.updateIssueStatusInListItem();
      this.$store.commit("PurchasedItem/CURRENT_ITEM_SET_ASSIGN", this.form);
      let data = { key: "pastReceivedQty", value: this.totalReceivedQty };
      this.$store.commit("PurchasedItem/CURRENT_ITEM_FIELD_SET", data);

    },

    // *********************************************
    //          Info popovers
    // *********************************************
    onClosePopover() {
      this.infoPopoverShow = false;
    },
  },
};
</script>

<style scoped>

.item-wrapper {
  max-width: 1000px;
  padding-left: 0;
  padding-right: 0;
}

.card-header {
  margin-bottom: 0;
}

.form-row {
  padding-right: 0;
}

.status-container {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  vertical-align: middle;
  margin-top: 15px;
  margin-right: 30px;
}

.status-text {
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
  margin-left: 5px;
}

.status-text-green {
  color: green;
}

.status-info-icon {
  margin-right: 5px !important;
  padding-top: 2px !important;
}

.info-popover {
  max-width: 500px !important;
}
@media (min-width: 768px) {
  .custom-popover {
    max-width: 400px !important;
  }
}

.history-scrollable-list {
  max-height: 300px;
  overflow-y: auto;
}

.expected-qty-container {
  display: flex;
  align-items: center;
  margin-top: -5px;
}
.expected-qty-icon {
  position: absolute;
  top: 0px;
  right: 157px;
}

.expected-summary-container {
  display: flex;
  position: absolute;
  left: 199px;
}
.expected-summary {
  font-size: 0.9rem;
  z-index: 4;
  margin-right: 8px;
}
.expected-summary-icon {
  z-index: 4;
}

.pending-qty-container {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 0px;
}
.pending-qty-icon {
  position: absolute;
  top: 1px;
  right: 160px;
}

.active-issue-flag-container {
  position: absolute;
  top: 12px;
  left: 210px;
  width: 22px;
  height: 22px;
  background-color: rgb(255, 240, 226);
  border: 1px solid #b77d00;
  border-radius: 50%;
  box-shadow: 1px 1px 6px rgba(141, 173, 199, 0.6); /* Optional: Add a glow effect */
  z-index: 1;
}

.active-issue-flag-icon {
  transition: opacity 0.3s ease, transform 0.3s ease;
  color: #f98e03;
  height: 13px;
  width: 13px;
  margin-left: 3px;
  margin-bottom: 3px;
}
.active-issue-flag-container:hover {
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3);
}

.progress-class {
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal-body {
  padding: 0 !important;
}

/* *********************************************
/          Received buttons (receive all and reset)
/  ********************************************* */

.receive-container {
  display: flex;
  align-content: center;
  align-items: center;
}

.receive-button {
  margin-right: 7px;
  font-size: 0.9rem;
  height: 30px;
  line-height: 16px;
  width: 135px;
}
.receive-all-button{
  margin-left: 26px;
}

.pi-header-container {
  display: flex;
  align-items: center;
}

.receive-activity-container {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  height: 30px;
  margin-bottom: 6px;
}

.receive-adj-button {
  position: absolute;
  left: 296px;
  top: 0px;
  width: 135px;
}
.receive-activity-icon {
  padding-bottom: 0px;
  margin-top: 5px;
  margin-bottom: 0;
}

.total-adj-container {
  position: absolute;
  left: 296px;
  top: 35px;
}

.total-adj-label {
  font-size: 0.9rem;
  color: #777;
  margin-right: 5px;
}

.copy-button-container {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.check-container {
  display: inline-block;
  position: absolute;
  left: 100%; /* Position the checkmark to the right of the button */
  margin-left: 4px;
  overflow: hidden;
}

.green-check {
  color: #009933;
  font-size: 0.9rem;
  display: inline-block;
}

@keyframes revealCheck {
  to {
    transform: translateX(0); /* Slide into view */
  }
}

@keyframes stayVisible {
  from {
    opacity: 1;
  }
  to {
    opacity: 1; /* No fade; purely for timing */
  }
}

.issue-receive-qty-exists {
  color: green;
  margin-left: -84px;
}

</style>
