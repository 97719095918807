<template class="template">
  <b-overlay
    variant="white"
    :show="isLoading"
    :opacity="0.0"
    spinner-variant="info"
    spinner-type="none"
    class="mx-auto sidebar-home-overlay "
  >
    <div class="sidebar-wrapper d-flex main-container-issue-home" >
      <!-- Show sidebar -->
      <b-button
        size="sm"
        class="sidebar-unhide-button"
        variant="light"
        v-if="sidebarHidden"
        @click.prevent="unhideSidebar"
      >
        <i class="fas fa-thumbtack unpin-icon sidebar-hide-icon"></i>
      </b-button>
      <!-- Side nav for Issues -->
      <div
        class="sidebar-body p-1 sidebar-border"
        :class="{ 'sidebar-hidden': sidebarHidden }"
      >
        <b-nav vertical class="sidebar-nav sidebar">
          <!-- Hide sidebar button -->
          <b-button
            size="sm"
            variant="light"
            class="ml-auto mb-0 sidebar-hide-button"
            @click.prevent="hideSidebar"
          >
            <i class="fas fa-thumbtack sidebar-hide-icon"></i>
          </b-button>
          <!-- Header -->
          <div class="pl-3 mt-4">
            <h5>Processing Issues</h5>
          </div>
          <hr class="mt-1" />
          <!-- Active issue assigned to client -->
          <b-nav-item
            ref="issue-active"
            :class="{'nav-item-active': isActive('IssueAssignedToClient')}"
            @click.prevent="onNavClick('IssueAssignedToClient')"
          >
            Issues Assgined to Client</b-nav-item
          >
          <!-- Active issue assigned to processor -->
          <b-nav-item
            ref="issue-active"
            :class="{'nav-item-active': isActive('IssueAssignedToProcessor')}"
            @click.prevent="onNavClick('IssueAssignedToProcessor')"
          >
            Issues Assgined to Processor</b-nav-item
          >
          <!-- Active issue assigned to me list -->
          <b-nav-item
            ref="issue-active"
            :class="{'nav-item-active': isActive('IssueAssignedToMe')}"
            @click.prevent="onNavClick('IssueAssignedToMe')"
          >
            Issues Assgined to Me</b-nav-item
          >
          <hr />
          <!-- Open issue list -->
          <b-nav-item
            ref="issue"
            :class="{'nav-item-active': isActive('IssueOpen')}"
            @click.prevent="onNavClick('IssueOpen')"
          >
            All Open Issues</b-nav-item
          >
          <!-- Closed issue list -->
          <b-nav-item
            ref="issue-closed"
            :class="{'nav-item-active': isActive('IssueClosed')}"
            @click.prevent="onNavClick('IssueClosed')"
          >
            All Closed Issues</b-nav-item
          >
          <!-- All issues -->
          <b-nav-item
            ref="issue"
            :class="{'nav-item-active': isActive('IssueAll')}"
            @click.prevent="onNavClick('IssueAll')"
          >
            All Issues</b-nav-item
          >
          <hr />
          <BaseExpandableMenu >
            <!-- Deleted issue list -->
            <b-nav-item
            :class="{'nav-item-active': isActive('IssueDeleted')}"
            @click.prevent="onNavClick('IssueDeleted')"
            >
              Issues - Deleted
            </b-nav-item>
          </BaseExpandableMenu>
        </b-nav>
      </div>
      <div class="main-content-overlay" @click="hideSidebar" ></div>
      <!-- Main content area -->
      <div id="issueMain" class="main-content p-0 flex-grow-1 m-0">
        <BaseBreadcrumb class="border-left"/>
        <router-view></router-view>
      </div>
    </div>
  </b-overlay>
</template>

<script>
// Links in this navigation view reference route names.
// In router (index.js), passes name as prop to component, which passes to store fetchAll.
import { mapGetters } from "vuex";
import { handleNavClick} from "../../utils/component-utils";
// Bootstrap sidebar menu
export default {
  components: {},
  props: {},
  data() {
    return {
      namespace: "issue",
      refreshTooltip: "Click to refresh summary",
    };
  },
  async created() {},
  mounted() {
  },
  computed: {
    ...mapGetters({
      isLoading: "Session/isLoading"
    }),
    ...mapGetters("Session", ["isAuthorized", "user", "breadcrumbTrail"]),
    myOrg() {
      return this.user.clientName;
    },
    sidebarHidden: {
      set(sidebarHidden) {
        this.$store.dispatch("Session/sidebarHidden", sidebarHidden);
      },
      get() {
        return this.$store.state["Session"].sidebarHidden;
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
    vm.handleBreadcrumbs(to, from);
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.handleBreadcrumbs(to, from);
    next();
  },

  methods: {

    handleBreadcrumbs(to) {
      const breadcrumbTrail = this.breadcrumbTrail || [];
      const lastBreadcrumb = breadcrumbTrail[breadcrumbTrail.length - 1]?.name;
      const firstBreadcrumb = breadcrumbTrail[0]?.name
      if (lastBreadcrumb === "PurchasedItem" && firstBreadcrumb.startsWith("Purchased")) {
        // Routing to Issue Detail: PI List > PI Detail > Issue Detail: Add last route
        this.$store.commit("Session/ADD_BREADCRUMB", { 
          name: to.name, 
          text: to.meta?.breadcrumb?.text, 
          title: to.meta?.breadcrumb?.title
        });
      } else if (lastBreadcrumb === "PurchasedItem" && firstBreadcrumb.startsWith("Issue")) {
        // Routing back to Issue Detail: Issue List > Issue Detail > PI Detail > Back to Issue Detail: Remove last route
        const lastIndex = breadcrumbTrail.map(item => item.name).lastIndexOf("PurchasedItem");
        this.$store.commit("Session/REMOVE_LAST_BREADCRUMB", lastIndex);
      } else if (lastBreadcrumb === "IssueItem") {
        // Routing back from Issue detail: Remove last
        const lastIndex = breadcrumbTrail.map(item => item.name).lastIndexOf("IssueItem");
        this.$store.commit("Session/REMOVE_LAST_BREADCRUMB", lastIndex);
      }
    },

    onNavClick(name) {
      handleNavClick(this, name)
    },

    hideSidebar() {
      this.sidebarHidden = true;
      document.querySelector('.main-content-overlay').style.display = 'none';
    },

    isActive(routeName) {
      return this.$route.name === routeName;
    },

    setTitle(text) {
      this.$store.dispatch(`Session/setSubNav`, text);
    },

    unhideSidebar() {
      this.sidebarHidden = false;
      document.querySelector('.main-content-overlay').style.display = 'block';
    },
  }
};
</script>

<style lang="css" scoped>

.red-border {
  border: 1px solid red;
}

.main-container-issue-home {
  height: calc(100vh - 48px);
}

/* .menu {
  list-style: none;
  padding: 0;
  margin: 0;
} */

/* .menu-item {
  margin: 5px 0;
} */

/* .menu-label {
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
} */

/* .menu-label-name {
  text-transform: uppercase;
  font-size: .8rem;
  font-weight: 800;
  color: steelblue;
} */

/* .menu-label span {
  flex-grow: 1;
} */

/* .chevron {
  transition: transform 0.3s ease;
  transform: rotate(0deg);
}

.chevron.expanded {
  transform: rotate(90deg);
}
 */
/* .submenu {
  margin-left: 20px;
  list-style: none;
  padding: 0;
}

.submenu-item {
  margin: 5px 0;
} */


</style>