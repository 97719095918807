<template>
  <div class="alert-wrapper" :style = "cssVars">
    <b-alert
          v-model="show"
      class="alert-message"
      :variant="variant"
      dismissible
      style="width: 35em; z-index: 2000;"
    >
      {{ alertState.message }}
    </b-alert>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: "danger",
    },
    mode: {
      type: String,
      default: "new",
    },
    width: {
      type: String,
      default: "35em",
    },
    top: {
      type: String,
      default: "4.9em",
    },
    timeout: {
      type: null,
      default: true,
    },
  },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("Notification/alertShowSet", false);
  },
  computed: {
    cssVars() {
      return {
        // Note to make this work, must specify ":style = 'cssVars'" in top div element above.
        "--top": this.top,
      }
    },
    state() {
      return this.$store.state[this.namespace];
    },
    alertState() {
      return this.$store.state["Notification"].alert;
    },
    show: {
      set(show) {
        this.$store.dispatch("Notification/alertShowSet", show);
      },
      get() {
        return this.$store.state["Notification"].alert.show && this.timeout;
      },
    },
  },
  methods: {},
};
</script>

<style lang="css" scoped>
b-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.alert-wrapper {
  position: fixed; 
  top: var(--top); 
  left: 50%;
  transform: translateX(-50%);
  width: 100%; 
  display: flex;
  justify-content: center;
  z-index: 2000;
}

.alert-message {
  width: 35em;
  margin: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.25em; /* Optional */
}
.alert-new {
  /* width: 45em; */
}
</style>