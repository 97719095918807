<template>
  <div class="outer-container">
    <div class="flex-container">
      <b-breadcrumb class="m-0 p-2 pl-3 bc-container border-bottom">
        <b-breadcrumb-item
          v-for="(crumb, index) in formattedBreadcrumbs"
          :key="index"
          :active="index === formattedBreadcrumbs.length - 1"
          @click="routeTo(index)"
        >
          {{ crumb.text }}
        </b-breadcrumb-item>
      </b-breadcrumb>
      <div class="title-container">
        <span class="centered-title table-title">{{ tableTitle }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BaseBreadcrumb",
  props: {
    title: {
      type: String,
      default: "Default Title"
    }
  }, data() {
    return {
      breadcrumbItems: [],
      tableTitle: ""
    };
  },
  mounted() {
    this.updateList();
  },
  computed: {
    ...mapGetters("Session", [
      "tenantSelectedClient",
      "scope",
      "breadcrumbTrail",
      "routeHistory"
    ]),

    formattedBreadcrumbs() {
      const bc = this.breadcrumbTrail.map((crumb, index) => ({
        text: crumb.text,
        active: crumb.active || false,
        index,
      }));
      return bc;
    },

    useTableTitle() {
      const lastCrumb = this.breadcrumbTrail?.slice(-1)[0] || {};
      let parseTitle = lastCrumb.title;
      if (this.scope === "tenant" && parseTitle) {
        return `${parseTitle} - ${this.tenantSelectedClient.clientName}`
      } else {
        return parseTitle;
      }
    },
  },
  watch: {
    $route() {
      this.updateList();
    },
    tenantSelectedClient() { 
      this.tableTitle = this.useTableTitle;
    }
  },
  methods: {

    routeTo(index) {
      if (index === this.formattedBreadcrumbs.length - 1) return;
      const targetRoute = this.breadcrumbTrail[index];
      if (!targetRoute || !targetRoute.name) return;
      const matchedRoute = this.routeHistory.find(route => route.name === targetRoute.name);
      if (matchedRoute) {
        this.$router.push(matchedRoute);
      } else {
        this.$router.push({ name: "NotFound" });
      }
    },

    isLastBreadcrumb(index) {
      return index === this.formattedBreadcrumbs.length - 1;
    },

    updateList() {
      this.tableTitle = this.useTableTitle;
    },
  }
};
</script>

<style lang="css" scoped>

.bc-container {
  background: transparent;
  font-size: .9rem;
}
.full-height {
  height: 100vh; /* or 100% if it's inside another container */
}
.outer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.flex-container {
  display: flex;
  align-items: center;
  width: 100%;
}
.title-container {
  flex: 1;
  display: flex;
  justify-content: center;
  margin-right: 100px;
}
.centered-title {
  text-align: center;
}

</style>
